/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActiveApplicationDto {
  application: Application;
  product: LeadProductDto;
  rep1KycValid: boolean;
  rep2KycValid: boolean;
}

export interface Address {
  /** @maxLength 64 */
  line1?: string | null;
  /** @maxLength 64 */
  line2?: string | null;
  /**
   * @minLength 1
   * @maxLength 48
   */
  city: string;
  /**
   * @minLength 1
   * @maxLength 16
   */
  zip: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  country: string;
}

export enum AddressType {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export interface AddressVerificationChallengeRequest {
  /** @format uuid */
  partyId: string;
  type: AddressType;
  address: string;
  /** @format uuid */
  leadId: string;
}

export interface AddressVerificationChallengeResponse {
  /** @format uuid */
  id: string;
  address: string;
  /** @format date-time */
  validTo: string;
}

export interface AggregatedRiskGradeDto {
  /** @format int32 */
  riskGradePartner?: number;
  /** @format int32 */
  riskGradeOffer?: number;
  /** @format int32 */
  forecastedRevenue?: number;
  /** @format int32 */
  maxLoanLimit?: number;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode;
}

export interface AllocationValidationResultDtoConditionValidationEntry {
  valid: boolean;
  thresholdValue?: number | null;
  actualValue?: number | null;
  contextMessage?: string | null;
}

export interface AllocationValidationResultDtoCustomerExposures {
  newCustomer?: boolean;
  generalExposure?: number;
  involvementsExposure?: number;
}

export type Application = BaseIdEntity &
  BaseMetadataEntity & {
    active?: boolean;
    /** @format uuid */
    customerId: string;
    /** @format uuid */
    offerId: string;
    /** @format uuid */
    leadId?: string | null;
    state: ApplicationState;
    inputState: ApplicationInputState;
    /** @format date-time */
    revisionListDownloadedAt?: string;
    amlState: ApplicationAmlState;
    /** @format date-time */
    amlStateUpdatedAt?: string;
    /** @format uuid */
    rep1Id: string;
    /** @format uuid */
    rep2Id?: string;
    requested: ProductParams1;
    purpose?: string;
    product?: ProductParams2;
    pep?: boolean;
    iban?: string;
    psdSupported?: boolean | null;
    /** @format date-time */
    submittedAt?: string;
    /** @format date-time */
    rejectedAt?: string;
    /** @format date-time */
    approvedAt?: string;
    /** @format date-time */
    contractCreatedAt?: string;
    /** @format date-time */
    contractSentAt?: string;
    /** @format date-time */
    contractSignedAt?: string;
    contractRefSigni?: string;
    rep1ContractSignUrl?: string;
    rep2ContractSignUrl?: string;
    externalRef?: string;
    /** @format uuid */
    termsId?: string | null;
    revenues?: ApplicationRevenues;
    rejectionReason?: ApplicationRejectionReason;
    /** @format uuid */
    approvalBy?: string;
    /** @format uuid */
    reviewedBy?: string;
    /** @format date-time */
    reviewedAt?: string;
  };

export enum ApplicationAmlState {
  PENDING = 'PENDING',
  MANUAL_CHECK = 'MANUAL_CHECK',
  KO = 'KO',
  SUCCESS = 'SUCCESS',
  EXPIRED = 'EXPIRED',
  ALT_CHECK_PENDING = 'ALT_CHECK_PENDING',
  ALT_CHECK_REQUESTED = 'ALT_CHECK_REQUESTED',
}

export enum ApplicationApprovalAction {
  APPROVE = 'APPROVE',
  APPROVE_CHANGED_TERMS = 'APPROVE_CHANGED_TERMS',
  REJECT = 'REJECT',
}

export interface ApplicationCreateDto {
  /** @format uuid */
  customerId: string;
  /** @format uuid */
  rep1Id: string;
  /** @format uuid */
  offerId: string;
  /** @format uuid */
  leadId: string;
  product: ProductParams;
}

export enum ApplicationInputState {
  REP2 = 'REP2',
  PEP = 'PEP',
  UBO = 'UBO',
  REVENUE = 'REVENUE',
  BANK = 'BANK',
  PSD = 'PSD',
  KYC = 'KYC',
  DONE = 'DONE',
}

export enum ApplicationRejectionReason {
  ACCOUNT_DATA_DISCREPANCY = 'ACCOUNT_DATA_DISCREPANCY',
  DANGEROUS_ACCOUNT_TRANSACTIONS = 'DANGEROUS_ACCOUNT_TRANSACTIONS',
  KO_CRITERIA = 'KO_CRITERIA',
  LOAN_PURPOSE_NOT_SUPPORTED = 'LOAN_PURPOSE_NOT_SUPPORTED',
  LOW_COMPANY_TURNOVER = 'LOW_COMPANY_TURNOVER',
  NOT_SUFFICIENT_DOCUMENTS = 'NOT_SUFFICIENT_DOCUMENTS',
  RESTRICTED_COMPANY_BUSINESS = 'RESTRICTED_COMPANY_BUSINESS',
  SHORT_COMPANY_HISTORY = 'SHORT_COMPANY_HISTORY',
  BLACKLIST_FOUND = 'BLACKLIST_FOUND',
  OTHER = 'OTHER',
  OVER_INDEBTED = 'OVER_INDEBTED',
  INSUFFICIENT_FUNDING = 'INSUFFICIENT_FUNDING',
  INSUFFICIENTPSD2DATA = 'INSUFFICIENT_PSD2_DATA',
}

export interface ApplicationRevenues {
  revenues3MonthAvg?: number | null;
  expenses3MonthAvg?: number | null;
  repaymentsMonthAvg?: number | null;
  revenuesYear?: number | null;
  expensesYear?: number | null;
  currency?: IsoCurrencyCode;
}

export enum ApplicationState {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  CHECKING = 'CHECKING',
  SENT_FOR_APPROVING = 'SENT_FOR_APPROVING',
  WAIT_NEW_CONDITIONS = 'WAIT_NEW_CONDITIONS',
  APPROVED = 'APPROVED',
  CONTRACT_SENT = 'CONTRACT_SENT',
  SIGNED = 'SIGNED',
  CLIENT_REFUSED = 'CLIENT_REFUSED',
  NOT_APPROVED = 'NOT_APPROVED',
  CANCELED = 'CANCELED',
}

export interface ApplicationSubmitBankDto {
  /**
   * Bank account number
   * @maxLength 34
   * @example "CZ6508000000192000145399"
   */
  iban: string;
}

export interface ApplicationSubmitKycDto {
  application: Application;
  kycToken: string;
}

export interface ApplicationSubmitPepDto {
  pep: boolean;
}

export interface ApplicationSubmitPsdDto {
  application: Application;
  psdState: PsdConnectionState;
}

export interface ApplicationSubmitRep2Dto {
  /** @format uuid */
  id: string;
  /**
   * @format email
   * @maxLength 255
   */
  email: string;
}

export interface ApplicationSubmitRep2DtoCustom {
  /** @maxLength 64 */
  firstName: string;
  /** @maxLength 64 */
  lastName: string;
  /** @format date */
  dob: string;
  /**
   * @format email
   * @maxLength 255
   */
  email: string;
  address: Address;
  /** @maxLength 24 */
  phone: string;
}

export interface ApplicationSubmitRevenuesDto {
  revenues: ApplicationRevenues;
}

export interface ApplicationSubmitUboDto {
  /** @maxLength 64 */
  firstName: string;
  /** @maxLength 64 */
  lastName: string;
  /** @format date */
  dob: string;
  /**
   * Two-letter country code as defined by ISO 3166-1 alpha-2
   * @minLength 2
   * @maxLength 2
   * @example "CZ"
   */
  nationality: string;
  address: Address;
  pep: boolean;
}

export interface BankDto {
  /** @format uuid */
  id: string;
  bic: string;
  code: string;
  name: string;
  active: boolean;
  psdSupported?: boolean;
  country: string;
}

export enum BankTransactionState {
  NEW = 'NEW',
  PAIRED = 'PAIRED',
  MANUAL_PAIRING = 'MANUAL_PAIRING',
  IGNORED = 'IGNORED',
}

export type BaseIdEntity = BaseMetadataEntity & {
  /** @format uuid */
  id?: string;
};

export interface BaseMetadataEntity {
  /** @format int64 */
  version?: number;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
}

export type BasePartyOrganizationEntity = BaseMetadataEntity & {
  id?: PartyOrganizationId;
  /** @format date-time */
  effectiveFrom: string;
  /** @format date-time */
  effectiveTo?: string;
  /** @format int32 */
  partyRank?: number;
  /** @format int32 */
  organizationRank?: number;
};

export interface BlacklistDto {
  /** @format uuid */
  id: string;
  type: BlacklistType;
  /** @format uuid */
  customerId: string;
  /** @format uuid */
  userId?: string;
  /** @format int32 */
  effectivePeriodMonths?: number;
  /** @format date */
  effectiveFrom?: string;
  /** @format date */
  effectiveTo?: string;
  note?: string;
  /** @format int64 */
  version?: number;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  updatedAt?: string;
  reasonCode?: BlacklistReasonCode;
}

export enum BlacklistReasonCode {
  FRAUDULENT_PARTY = 'FRAUDULENT_PARTY',
  TERRORIST_PARTY = 'TERRORIST_PARTY',
  INTERNAL_BLACKLIST = 'INTERNAL_BLACKLIST',
  ACCOUNT_TAKEOVER = 'ACCOUNT_TAKEOVER',
  ID_FRAUD = 'ID_FRAUD',
  RISK_DECISION = 'RISK_DECISION',
  UNDESIRABLE_INFORMATION = 'UNDESIRABLE_INFORMATION',
  HARD_COLLECTIONS_CASE = 'HARD_COLLECTIONS_CASE',
  SEVERE_OVERDUE = 'SEVERE_OVERDUE',
}

export enum BlacklistType {
  TEMPORARY = 'TEMPORARY',
  PERMANENT = 'PERMANENT',
}

export interface CategoryAmounts {
  category?: string;
  credit?: number;
  debit?: number;
  /** @format int64 */
  txCount?: number;
}

export type CategorySentimentAmounts = CategoryAmounts & {
  sentiment?: string;
};

export type CollectionAction = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    collectionId: string;
    type: CollectionActionType;
  };

export type CollectionActionNote = BaseIdEntity &
  BaseMetadataEntity & {
    note: string;
    /** @format date-time */
    dateTime: string;
  };

export type CollectionActionNoteDto = CollectionActionNote &
  BaseIdEntity &
  BaseMetadataEntity & {
    userName?: string;
  };

export enum CollectionActionType {
  FORCE_COLLECTION = 'FORCE_COLLECTION',
  PROMISE_TO_PAY = 'PROMISE_TO_PAY',
  BUSINESS_NEGOTIATION = 'BUSINESS_NEGOTIATION',
  HANDED_LEGAL = 'HANDED_LEGAL',
  DEFAULT_NOTICE = 'DEFAULT_NOTICE',
  EXTERNAL_COLLECTION_PROCESS = 'EXTERNAL_COLLECTION_PROCESS',
  LEGAL_NEGOTIATION = 'LEGAL_NEGOTIATION',
  PRELITIGATION_NOTICE_CUSTOMER = 'PRELITIGATION_NOTICE_CUSTOMER',
  PRELITIGATION_NOTICE_GUARANTOR = 'PRELITIGATION_NOTICE_GUARANTOR',
  LEGAL_ACTION = 'LEGAL_ACTION',
  LEGAL_ACTION_DISPUTED = 'LEGAL_ACTION_DISPUTED',
  INSOLVENCY_ACTION = 'INSOLVENCY_ACTION',
  FORECLOSURE = 'FORECLOSURE',
  PAID_COLLECTION = 'PAID_COLLECTION',
  RESTRUCTURED = 'RESTRUCTURED',
  INSOLVENCY_CUSTOMER = 'INSOLVENCY_CUSTOMER',
  INSOLVENCY_GUARANTOR = 'INSOLVENCY_GUARANTOR',
  FORECLOSURE_CUSTOMER = 'FORECLOSURE_CUSTOMER',
  FORECLOSURE_GUARANTOR = 'FORECLOSURE_GUARANTOR',
}

export enum CollectionFeeCollectionFeeState {
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
}

export enum CollectionFeeCollectionFeeType {
  COURT_FEE_FLOWPAY = 'COURT_FEE_FLOWPAY',
  COURT_FEE_CLIENT = 'COURT_FEE_CLIENT',
  LEGAL_REPRESENTATION = 'LEGAL_REPRESENTATION',
}

export interface CollectionRiskProfileTag {
  empty?: boolean;
}

export enum CommissionState {
  NEW = 'NEW',
  PAID = 'PAID',
  PENDING = 'PENDING',
}

export interface CommissionReportEntry {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  partnerId?: string;
  /** @format uuid */
  loanId?: string;
  loanInternalRef?: string;
  loanExternalRef?: string;
  /** @format uuid */
  customerId?: string;
  customerName?: string;
  loanPrincipal?: number;
  loanFee?: number;
  amount?: number;
  loanPaidPerc?: number;
  /** @format date */
  loanDisbursedAt?: string;
  /** @format date */
  effectiveAt?: string;
  /** @format date */
  paidAt?: string;
}

export enum CommissionSchemaCalculationType {
  FLAT = 'FLAT',
  PA = 'PA',
}

export enum CommissionSchemaPaymentType {
  INSTALMENT_REPAYMENT = 'INSTALMENT_REPAYMENT',
  LOAN_REPAYMENT = 'LOAN_REPAYMENT',
  FIRST_INSTALLMENT = 'FIRST_INSTALLMENT',
  SHOPTET = 'SHOPTET',
}

export type CommissionStatement = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    partnerId?: string;
    period?: string;
    filename?: string;
    amount?: number;
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    currency?: IsoCurrencyCode;
    state?: CommissionStatementCommissionStatementState;
    /** @format date */
    paidAt?: string;
    bankRef?: string;
  };

export enum CommissionStatementCommissionStatementState {
  PENDING = 'PENDING',
  PAID = 'PAID',
  NEW = 'NEW',
}

export enum CommunicationHistoryEntryDtoCommunicationHistoryEntrySource {
  COLLECTION = 'COLLECTION',
  FINANCING = 'FINANCING',
  LEAD = 'LEAD',
  GENERAL = 'GENERAL',
}

export interface ConsentDto {
  /** @format uuid */
  partyId: string;
  consentGiven: boolean;
  /** @format date-time */
  consentChangedAt: string;
}

export interface ContactFormDto {
  /** @minLength 1 */
  message: string;
  contactFormContext: ContactFormDtoContactFormContext;
}

export enum ContactFormDtoContactFormContext {
  PROLONGATION = 'PROLONGATION',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  OTHER = 'OTHER',
}

export interface CounterPartyAmountSum {
  counterName?: string;
  counterAccount?: string;
  amountSum?: number;
}

export interface CounterPartyTxCount {
  counterName?: string;
  counterAccount?: string;
  /** @format int64 */
  txCount?: number;
}

export interface CountryDto {
  /**
   * Two-letter country code as defined by ISO 3166-1 alpha-2
   * @minLength 2
   * @maxLength 2
   * @example "CZ"
   */
  code?: string;
  name: string;
  /**
   * National phone code
   * @maxLength 4
   */
  phoneCode?: string;
  /** Currency as defined by ISO 4217 */
  currency?: IsoCurrencyCode;
}

export interface CreateConnectionFromLeadDto {
  leadId?: string;
}

export interface CreateConnectionShopifyDto {
  /** @format uuid */
  customerId: string;
  tenantId: string;
}

export interface CreateCustomerDto {
  /**
   * Two-letter country code as defined by ISO 3166-1 alpha-2
   * @minLength 2
   * @maxLength 2
   * @example "CZ"
   */
  country: string;
  /**
   * Identification number of the company in business register
   * @maxLength 32
   */
  regNum: string;
  segment: string;
  urls: string[];
  /** @format uuid */
  leadId: string;
}

export interface CreateCustomerRepDtoAddressDto {
  /** @maxLength 64 */
  line1?: string | null;
  /** @maxLength 64 */
  line2?: string | null;
  /** @maxLength 48 */
  city?: string | null;
  /** @maxLength 16 */
  zip?: string | null;
  /**
   * @minLength 2
   * @maxLength 2
   */
  country?: string | null;
}

export interface CreateCustomerRepDtoRegDto {
  roleText: string;
  statutory: boolean;
  /** @format date */
  effectiveFrom: string;
  /** @format date */
  effectiveTo: string;
  intermediatePartyNames: string[];
}

export interface CreateFinancingRequestRepresentative {
  /**
   * Identifier of the representative
   * @format uuid
   */
  id: string;
  /**
   * Email address
   * @format email
   */
  email?: string;
  /**
   * Phone number in international format E.123
   * @maxLength 24
   */
  phone?: string;
}

export interface CreateIndicativeInterestDto {
  /** @format uuid */
  offerId?: string;
  partnerCode?: string;
  merchantId?: string;
  productParams?: ProductParams;
  /** @maxLength 256 */
  referrer?: string;
  /** @maxLength 256 */
  utm_source?: string;
  /** @maxLength 256 */
  utm_campaign?: string;
  /** @maxLength 256 */
  utm_medium?: string;
  /** @maxLength 256 */
  utm_content?: string;
  /** @maxLength 256 */
  utm_term?: string;
  /** @maxLength 256 */
  utm_id?: string;
}

export interface CreateInterestDto {
  /** @format uuid */
  offerId?: string;
  partnerCode?: string;
  merchantId?: string;
  productParams?: ProductParams;
}

export interface CreateLeadContactDto {
  /** @format email */
  email?: string;
  phone?: string;
  country?: string;
  regNum?: string;
  /** @format uuid */
  leadId?: string;
}

export interface CreateLeadNoOfferDto {
  partnerCode?: string;
  merchantId?: string;
}

export interface CreateLeadOpportunityDto {
  /** @format uuid */
  offerId: string;
  /** @format uuid */
  customerId?: string;
  /** @maxLength 256 */
  referrer?: string;
  /** @maxLength 256 */
  utm_source?: string;
  /** @maxLength 256 */
  utm_campaign?: string;
  /** @maxLength 256 */
  utm_medium?: string;
  /** @maxLength 256 */
  utm_content?: string;
  /** @maxLength 256 */
  utm_term?: string;
  /** @maxLength 256 */
  utm_id?: string;
}

export interface CreateLeadSignupDto {
  /** @format uuid */
  userId?: string;
  /** @format email */
  email?: string;
  /** @maxLength 256 */
  referrer?: string;
  /** @maxLength 256 */
  utm_source?: string;
  /** @maxLength 256 */
  utm_campaign?: string;
  /** @maxLength 256 */
  utm_medium?: string;
  /** @maxLength 256 */
  utm_content?: string;
  /** @maxLength 256 */
  utm_term?: string;
  /** @maxLength 256 */
  utm_id?: string;
  /** @format uuid */
  leadId?: string;
}

export interface CreateLoanProlongationDto {
  /** @format uuid */
  loanId: string;
  /** @minLength 1 */
  prolongationReason: string;
  internalNote: string;
  origin: LoanProlongationOrigin;
  actionType?: ServicingActionType;
}

export interface CreateOfferLegacyDtoCreateOfferProduct {
  period: string;
  /** @format int32 */
  riskGrade: number;
  interestRate: number;
  revenueForecast: number;
  maxLoanAmount: number;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode;
}

export interface CustomerDto {
  organization: Party;
  /** @format date-time */
  effectiveFrom?: string;
  /** @format date-time */
  effectiveTo?: string;
}

export interface CustomerRepWithContactDto {
  /** @format uuid */
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: Address;
  /** @format date-time */
  effectiveFrom: string;
  /** @format date-time */
  effectiveTo: string;
}

export enum CustomerRiskNoteState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum CustomerScoringAction {
  START_NEW_SCORING = 'START_NEW_SCORING',
  RESTART_UNDERWRITING = 'RESTART_UNDERWRITING',
  RESTART_DISTRAINTCHECK = 'RESTART_DISTRAINTCHECK',
  SEND_FOR_MANUAL_APPROVAL = 'SEND_FOR_MANUAL_APPROVAL',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export enum CustomerScoringCreditcheckState {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  MANUAL_CHECK = 'MANUAL_CHECK',
  SUCCESS = 'SUCCESS',
  KO = 'KO',
  ERROR = 'ERROR',
}

export enum CustomerScoringDistraintcheckState {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  MANUAL_CHECK = 'MANUAL_CHECK',
  SUCCESS = 'SUCCESS',
  KO = 'KO',
  ERROR = 'ERROR',
}

export enum CustomerScoringState {
  PENDING = 'PENDING',
  CREDITCHECK_SCHEDULED = 'CREDITCHECK_SCHEDULED',
  CREDITCHECK_PROCESSING = 'CREDITCHECK_PROCESSING',
  CREDITCHECK_MANUAL_CHECK = 'CREDITCHECK_MANUAL_CHECK',
  CREDITCHECK_ERROR = 'CREDITCHECK_ERROR',
  DISTRAINTCHECK_SCHEDULED = 'DISTRAINTCHECK_SCHEDULED',
  DISTRAINTCHECK_PROCESSING = 'DISTRAINTCHECK_PROCESSING',
  DISTRAINTCHECK_MANUAL_CHECK = 'DISTRAINTCHECK_MANUAL_CHECK',
  DISTRAINTCHECK_ERROR = 'DISTRAINTCHECK_ERROR',
  SUCCESS = 'SUCCESS',
  KO = 'KO',
  ERROR = 'ERROR',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface DateRange {
  /** @format date */
  from?: string;
  /** @format date */
  till?: string;
}

export enum DebtAllocationParamsValidationMode {
  ALLOCATION = 'ALLOCATION',
  REALLOCATION = 'REALLOCATION',
}

export enum DebtFacilityDebtCapitalType {
  JUNIOR = 'JUNIOR',
  SENIOR = 'SENIOR',
}

export enum DebtFacilityDebtFacilityType {
  OWN_FUNDS = 'OWN_FUNDS',
  LOAN = 'LOAN',
}

export interface DebtFacilityDtoRestructuringConditionDto {
  /**
   * @format int32
   * @min 1
   * @max 5
   */
  restructuredCount?: number;
  retentionRate?: number;
  /** @format int32 */
  paymentsForReset?: number;
}

export interface DebtFacilityDtoTermsDto {
  /** @format uuid */
  debtFacilityId?: string | null;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode;
  basicInterest: number;
  rateType: DebtFacilityTermsVariableRateType;
  ratePeriod: DebtFacilityTermsVariableRatePeriod;
}

export enum DebtFacilityTermsVariableRatePeriod {
  M1 = 'M1',
  M2 = 'M2',
  M3 = 'M3',
  M6 = 'M6',
  M9 = 'M9',
  M12 = 'M12',
}

export enum DebtFacilityTermsVariableRateType {
  PRIBOR = 'PRIBOR',
  EURIBOR = 'EURIBOR',
}

export interface DebtFacilityTrancheDtoManualPaymentDto {
  /** @format date */
  dueDate: string;
  interestAmount: number;
  principalAmount: number;
}

export interface DebtFacilityTrancheDtoSystemPaymentDto {
  autoCalculationDate: string;
  /**
   * @format int32
   * @min 0
   */
  dueDays?: number;
  dayCountConvention: TrancheInterestCalculationPlanDayCountConvention;
  interestCalcBase: TrancheInterestCalculationPlanTrancheInterestCalcBase;
}

export interface DebtProviderAllocationDto {
  /** @format uuid */
  trancheId: string;
  amount?: number | null;
  /**
   * @min 0.01
   * @max 100
   */
  allocatedCoveragePerc: number;
}

export interface DebtProviderDtoDebtProviderExposureDto {
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode;
  minExposure: number;
  maxExposureNewCustomer: number;
  maxExposureGeneral: number;
}

export interface DetailedCategoryAmount {
  category?: string;
  detailedCategory?: string;
  amount?: number;
}

export interface DocumentDto {
  /** @format uuid */
  id: string;
  type: string;
  fileBase64: string;
  fileName?: string;
  contentType?: string;
  /** @format uuid */
  applicationId: string;
  /** @format uuid */
  partyId: string;
  /** @format int64 */
  version: number;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}

export type ExternalData = BaseIdEntity &
  BaseMetadataEntity & {
    externalId?: string;
    /** @format uuid */
    partyId?: string;
    subjectId: string;
    source: string;
    type: string;
    subjectName?: string;
    requestUrl?: string;
    requestHeaders?: string;
    requestBody?: string;
    /** @format int32 */
    responseStatus?: number;
    responseHeaders?: string;
    responseBody?: string;
    responseJson?: string;
    responseParsed?: any;
    /** @format date-time */
    validTo?: string;
  };

export interface ExternalUserDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  partyId: string;
  /** @format email */
  email: string;
  /** @maxLength 128 */
  name: string;
  userId: string;
  type: UserType;
  consentGiven: boolean;
  customers: ExternalUserDtoCustomerDto[];
  partner: ExternalUserDtoPartnerDto;
  partners: ExternalUserDtoPartnerDto[];
  /** @maxLength 24 */
  phone: string;
  /** @format date-time */
  phoneVerifiedAt: string;
  invited: boolean;
}

export interface ExternalUserDtoConnectedPartnerDto {
  name?: string;
  code?: string;
  merchantId?: string;
  tenantId?: string;
}

export interface ExternalUserDtoCustomerDto {
  /** @format uuid */
  id?: string;
  /** @maxLength 128 */
  name?: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  country?: string;
  regNum?: string;
  partners?: ExternalUserDtoConnectedPartnerDto[];
  /** @format uuid */
  linkedRegRepId?: string;
}

export interface ExternalUserDtoPartnerDto {
  /** @format uuid */
  id: string;
  /** @maxLength 128 */
  name: string;
  /** @maxLength 128 */
  code: string;
  /**
   * @minLength 2
   * @maxLength 2
   */
  country: string;
  /** @maxLength 32 */
  regNum: string;
  /** @maxLength 32 */
  accountNumber: string;
}

export interface FinancingInstallment {
  /**
   * Due date
   * @format date
   * @example "2020-12-21T00:00:00.000Z"
   */
  dueDate?: string;
  /**
   * Paid date
   * @format date
   * @example "2020-12-21T00:00:00.000Z"
   */
  paidDate?: string;
  /** Total installment amount */
  total?: number;
  /** Principal */
  principal?: number;
  /** Fee */
  fee?: number;
  /** Prolongation or postponement fee */
  feeProlongationOrPostpone?: number;
  /** Total paid amount */
  paidTotal?: number;
  /** Currency as defined by ISO 4217 */
  currency?: IsoCurrencyCode;
  /** Installment state */
  state?: InstallmentInstallmentState;
}

export enum FinancingParameterFinancingParameterValueType {
  PERCENTAGE = 'PERCENTAGE',
  FLAT_FEE = 'FLAT_FEE',
}

export enum FinancingParameterType {
  PENALTY_FEE = 'PENALTY_FEE',
  PROLONGATIONFEE1 = 'PROLONGATION_FEE_1',
  PROLONGATIONFEE2 = 'PROLONGATION_FEE_2',
}

export enum FinancingState {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  CHECKING = 'CHECKING',
  SENT_FOR_APPROVING = 'SENT_FOR_APPROVING',
  WAIT_NEW_CONDITIONS = 'WAIT_NEW_CONDITIONS',
  APPROVED = 'APPROVED',
  CONTRACT_SENT = 'CONTRACT_SENT',
  SIGNED = 'SIGNED',
  PENDING_DISBURSEMENT = 'PENDING_DISBURSEMENT',
  DISBURSED = 'DISBURSED',
  PAID = 'PAID',
  CLIENT_REFUSED = 'CLIENT_REFUSED',
  NOT_APPROVED = 'NOT_APPROVED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  OVERDUE = 'OVERDUE',
  COLLECTION = 'COLLECTION',
}

export enum FinancingState1 {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  CHECKING = 'CHECKING',
  WAIT_NEW_CONDITIONS = 'WAIT_NEW_CONDITIONS',
  APPROVED = 'APPROVED',
  CONTRACT_SENT = 'CONTRACT_SENT',
  SIGNED = 'SIGNED',
  PENDING_DISBURSEMENT = 'PENDING_DISBURSEMENT',
  DISBURSED = 'DISBURSED',
  PAID = 'PAID',
  CLIENT_REFUSED = 'CLIENT_REFUSED',
  NOT_APPROVED = 'NOT_APPROVED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  OVERDUE = 'OVERDUE',
  COLLECTION = 'COLLECTION',
}

export enum FinancingState2 {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  CHECKING = 'CHECKING',
  WAIT_NEW_CONDITIONS = 'WAIT_NEW_CONDITIONS',
  APPROVED = 'APPROVED',
  CONTRACT_SENT = 'CONTRACT_SENT',
  SIGNED = 'SIGNED',
  PENDING_DISBURSEMENT = 'PENDING_DISBURSEMENT',
  DISBURSED = 'DISBURSED',
  PAID = 'PAID',
  CLIENT_REFUSED = 'CLIENT_REFUSED',
  NOT_APPROVED = 'NOT_APPROVED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  OVERDUE = 'OVERDUE',
  COLLECTION = 'COLLECTION',
}

export enum FinancingState3 {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  CHECKING = 'CHECKING',
  WAIT_NEW_CONDITIONS = 'WAIT_NEW_CONDITIONS',
  APPROVED = 'APPROVED',
  CONTRACT_SENT = 'CONTRACT_SENT',
  SIGNED = 'SIGNED',
  PENDING_DISBURSEMENT = 'PENDING_DISBURSEMENT',
  DISBURSED = 'DISBURSED',
  PAID = 'PAID',
  CLIENT_REFUSED = 'CLIENT_REFUSED',
  NOT_APPROVED = 'NOT_APPROVED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  OVERDUE = 'OVERDUE',
  COLLECTION = 'COLLECTION',
}

/** Countries supported by FlowPay */
export enum FlowpayCountry {
  CZ = 'CZ',
  SK = 'SK',
  NL = 'NL',
}

export interface HotglueConnectionCreatedDto {
  env?: string;
  flow?: string;
  tap?: string;
}

export interface InsightReportDtoAccountData {
  credit: number;
  debit: number;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode;
}

export interface InsightReportDtoDataAvailability {
  monthRange?: DateRange;
  /** @format date */
  firstTransactionDate?: string;
  /** @format date */
  lastTransactionDate?: string;
}

export interface InsightRevenueDto {
  partnerCode?: string;
  /** @format date */
  date?: string;
  /** @format int32 */
  revenue?: number;
  /** @format int32 */
  orderCount?: number;
  /** @format int32 */
  cancelledOrderCount?: number;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode;
}

export type Installment = BaseIdEntity &
  BaseMetadataEntity & {
    outstandingAmount?: number;
    outstandingPrincipal?: number;
    outstandingFeeReminder1?: number;
    outstandingFeeReminder2?: number;
    outstandingNotaryFee?: number;
    outstandingFee?: number;
    outstandingPenaltyFee?: number;
    outstandingProlongationFee?: number;
    /** @format uuid */
    loanId: string;
    state: InstallmentInstallmentState;
    /** @format date */
    validFrom?: string | null;
    /** @format date */
    validTo?: string | null;
    /** @format date */
    dueDate: string;
    type: InstallmentType;
    principal: number;
    fee: number;
    feeProlongation: number;
    feePenalty: number;
    feeReminder1: number;
    feeReminder2: number;
    notaryFee: number;
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    currency: IsoCurrencyCode;
    total: number;
    /** @format uuid */
    collectionId?: string;
    /** @format date */
    paidDate?: string;
    paidPrincipal?: number;
    paidFee?: number;
    paidFeeProlongation?: number;
    paidFeePenalty?: number;
    paidFeeReminder1?: number;
    paidFeeReminder2?: number;
    paidNotaryFee?: number;
    paidTotal?: number;
    paymentNote?: string;
    /** @format uuid */
    prolongationId?: string;
    /** @format uuid */
    scheduleAgreementId?: string;
    feePenaltyCalculated?: number;
    /** @format date */
    feePenaltyCalculatedDate?: string;
    /** @format int64 */
    daysOverdue?: number;
  };

export enum InstallmentInstallmentState {
  PENDING = 'PENDING',
  PAID = 'PAID',
  PLANNED = 'PLANNED',
  OVERDUE = 'OVERDUE',
  CANCELED = 'CANCELED',
  RESCHEDULED = 'RESCHEDULED',
  PROLONGED = 'PROLONGED',
}

export enum InstallmentType {
  STANDARD = 'STANDARD',
  PROLONGATION_FEE = 'PROLONGATION_FEE',
  AGREEMENT = 'AGREEMENT',
  AGREEMENT_FEE = 'AGREEMENT_FEE',
  DEFAULT = 'DEFAULT',
}

export enum InternalUserRole {
  ADMIN = 'ADMIN',
  OPERATOR = 'OPERATOR',
  UNDERWRITER = 'UNDERWRITER',
  LEGAL = 'LEGAL',
  ACCOUNTING = 'ACCOUNTING',
}

/** ISO 3166 alpha-2 country code enum, including metadata. */
export enum IsoCountryCode {
  AF = 'AF',
  AX = 'AX',
  AL = 'AL',
  DZ = 'DZ',
  AS = 'AS',
  AD = 'AD',
  AO = 'AO',
  AI = 'AI',
  AQ = 'AQ',
  AG = 'AG',
  AR = 'AR',
  AM = 'AM',
  AW = 'AW',
  AU = 'AU',
  AT = 'AT',
  AZ = 'AZ',
  BS = 'BS',
  BH = 'BH',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BM = 'BM',
  BT = 'BT',
  BO = 'BO',
  BQ = 'BQ',
  BA = 'BA',
  BW = 'BW',
  BV = 'BV',
  BR = 'BR',
  IO = 'IO',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  CV = 'CV',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  KY = 'KY',
  CF = 'CF',
  CC = 'CC',
  CO = 'CO',
  KM = 'KM',
  CD = 'CD',
  CG = 'CG',
  CK = 'CK',
  CR = 'CR',
  CI = 'CI',
  HR = 'HR',
  CU = 'CU',
  CW = 'CW',
  CY = 'CY',
  CZ = 'CZ',
  DK = 'DK',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  SZ = 'SZ',
  ET = 'ET',
  FK = 'FK',
  FO = 'FO',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  PF = 'PF',
  TF = 'TF',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GI = 'GI',
  GR = 'GR',
  GL = 'GL',
  GD = 'GD',
  GP = 'GP',
  GU = 'GU',
  GT = 'GT',
  GG = 'GG',
  GN = 'GN',
  GW = 'GW',
  GY = 'GY',
  HT = 'HT',
  HM = 'HM',
  VA = 'VA',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  CX = 'CX',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IR = 'IR',
  IQ = 'IQ',
  IE = 'IE',
  IM = 'IM',
  IL = 'IL',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  JE = 'JE',
  JO = 'JO',
  KZ = 'KZ',
  KE = 'KE',
  KI = 'KI',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KG = 'KG',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MO = 'MO',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MH = 'MH',
  MQ = 'MQ',
  MR = 'MR',
  MU = 'MU',
  YT = 'YT',
  MX = 'MX',
  FM = 'FM',
  MD = 'MD',
  MC = 'MC',
  MN = 'MN',
  ME = 'ME',
  MS = 'MS',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  NC = 'NC',
  NZ = 'NZ',
  NI = 'NI',
  NE = 'NE',
  NG = 'NG',
  NU = 'NU',
  NF = 'NF',
  MK = 'MK',
  MP = 'MP',
  NO = 'NO',
  OM = 'OM',
  PK = 'PK',
  PW = 'PW',
  PS = 'PS',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PN = 'PN',
  PL = 'PL',
  PT = 'PT',
  PR = 'PR',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  BL = 'BL',
  SH = 'SH',
  KN = 'KN',
  LC = 'LC',
  MF = 'MF',
  PM = 'PM',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SA = 'SA',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SX = 'SX',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  GS = 'GS',
  SS = 'SS',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SJ = 'SJ',
  SE = 'SE',
  CH = 'CH',
  SY = 'SY',
  TW = 'TW',
  TJ = 'TJ',
  TZ = 'TZ',
  TH = 'TH',
  TL = 'TL',
  TG = 'TG',
  TK = 'TK',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TR = 'TR',
  TM = 'TM',
  TC = 'TC',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  GB = 'GB',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VU = 'VU',
  VE = 'VE',
  VN = 'VN',
  VG = 'VG',
  VI = 'VI',
  WF = 'WF',
  EH = 'EH',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW',
}

/** ISO 4217 alpha-3 currency code enum, including metadata. */
export enum IsoCurrencyCode {
  ADP = 'ADP',
  AED = 'AED',
  AFA = 'AFA',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  ATS = 'ATS',
  AUD = 'AUD',
  AWG = 'AWG',
  AYM = 'AYM',
  AZM = 'AZM',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BEF = 'BEF',
  BGL = 'BGL',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BOV = 'BOV',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYB = 'BYB',
  BYN = 'BYN',
  BYR = 'BYR',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CLF = 'CLF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  COU = 'COU',
  CRC = 'CRC',
  CSD = 'CSD',
  CSK = 'CSK',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CYP = 'CYP',
  CZK = 'CZK',
  DEM = 'DEM',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EEK = 'EEK',
  EGP = 'EGP',
  ERN = 'ERN',
  ESP = 'ESP',
  ETB = 'ETB',
  EUR = 'EUR',
  FIM = 'FIM',
  FJD = 'FJD',
  FKP = 'FKP',
  FRF = 'FRF',
  GBP = 'GBP',
  GEL = 'GEL',
  GHC = 'GHC',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GRD = 'GRD',
  GTQ = 'GTQ',
  GWP = 'GWP',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  CHE = 'CHE',
  CHF = 'CHF',
  CHW = 'CHW',
  IDR = 'IDR',
  IEP = 'IEP',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  ITL = 'ITL',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LTL = 'LTL',
  LUF = 'LUF',
  LVL = 'LVL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MGF = 'MGF',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MRU = 'MRU',
  MTL = 'MTL',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MXV = 'MXV',
  MYR = 'MYR',
  MZM = 'MZM',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NLG = 'NLG',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PTE = 'PTE',
  PYG = 'PYG',
  QAR = 'QAR',
  ROL = 'ROL',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RUR = 'RUR',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDD = 'SDD',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SIT = 'SIT',
  SKK = 'SKK',
  SLE = 'SLE',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SRG = 'SRG',
  SSP = 'SSP',
  STD = 'STD',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMM = 'TMM',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TPE = 'TPE',
  TRL = 'TRL',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  USN = 'USN',
  USS = 'USS',
  UYI = 'UYI',
  UYU = 'UYU',
  UYW = 'UYW',
  UZS = 'UZS',
  VEB = 'VEB',
  VED = 'VED',
  VEF = 'VEF',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XAG = 'XAG',
  XAU = 'XAU',
  XBA = 'XBA',
  XBB = 'XBB',
  XBC = 'XBC',
  XBD = 'XBD',
  XCD = 'XCD',
  XDR = 'XDR',
  XFO = 'XFO',
  XFU = 'XFU',
  XOF = 'XOF',
  XPD = 'XPD',
  XPF = 'XPF',
  XPT = 'XPT',
  XSU = 'XSU',
  XTS = 'XTS',
  XUA = 'XUA',
  XXX = 'XXX',
  YER = 'YER',
  YUM = 'YUM',
  ZAR = 'ZAR',
  ZMK = 'ZMK',
  ZMW = 'ZMW',
  ZWD = 'ZWD',
  ZWL = 'ZWL',
  ZWN = 'ZWN',
  ZWR = 'ZWR',
}

export enum KycVerificationState {
  REQUESTED = 'REQUESTED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  MANUAL_REVIEW = 'MANUAL_REVIEW',
  EXPIRED = 'EXPIRED',
  INVALID = 'INVALID',
}

export interface LeadDto {
  /** @format uuid */
  id: string;
  partnerCode: string;
  merchantId: string;
  state: LeadState;
  salesState?: LeadSalesState;
  /** @format uuid */
  offerId: string;
  product?: LeadProductDto;
  existingCustomer?: boolean;
}

export interface LeadProductDto {
  type: ProductType;
  amount: number;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode;
  amountComplete: number;
  feeMonthly: number;
  feeProlongation: number;
  /** @format int32 */
  postponedPeriods: number;
  prolonged: boolean;
  crowdfunded: boolean;
}

export enum LeadSalesState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DEAD = 'DEAD',
  HOT_LEAD = 'HOT_LEAD',
  NOT_RELEVANT = 'NOT_RELEVANT',
  NOT_INTERESTED = 'NOT_INTERESTED',
  NO_ANSWER = 'NO_ANSWER',
  SUCCESSFUL = 'SUCCESSFUL',
  LACK_OF_INFO = 'LACK_OF_INFO',
  IN_FUTURE = 'IN_FUTURE',
}

export enum LeadState {
  LEAD = 'LEAD',
  PROSPECT = 'PROSPECT',
  CONTACT = 'CONTACT',
  SIGNUP = 'SIGNUP',
  CONNECTED = 'CONNECTED',
  UNQUALIFIED = 'UNQUALIFIED',
  ACTIVE_PRODUCT = 'ACTIVE_PRODUCT',
  SINGLE_OFFER = 'SINGLE_OFFER',
  OPPORTUNITY = 'OPPORTUNITY',
  INTEREST = 'INTEREST',
  INDICATIVE = 'INDICATIVE',
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  SUCCESSFUL = 'SUCCESSFUL',
  NO_OFFER = 'NO_OFFER',
  OFFER_PROCESSING_ERROR = 'OFFER_PROCESSING_ERROR',
  MERCHANT_DATA_ERROR = 'MERCHANT_DATA_ERROR',
  SERVICE_ACTIVATED = 'SERVICE_ACTIVATED',
}

export enum LoanLoanState {
  CREATED = 'CREATED',
  DISBURSED = 'DISBURSED',
  OVERDUE = 'OVERDUE',
  COLLECTION = 'COLLECTION',
  PAID = 'PAID',
  FAILED = 'FAILED',
}

export enum LoanDebtAllocationLoanDebtAllocationSource {
  DISBURSEMENT = 'DISBURSEMENT',
  DISBURSEMENT_CANCELATION = 'DISBURSEMENT_CANCELATION',
  BUYBACK_DPD = 'BUYBACK_DPD',
  BUYBACK_RESTRUCTURED = 'BUYBACK_RESTRUCTURED',
  BUYBACK_FRAUD = 'BUYBACK_FRAUD',
  BUYBACK_BANKRUPTCY = 'BUYBACK_BANKRUPTCY',
  REALLOCATION = 'REALLOCATION',
}

export enum LoanDefaultReason {
  CONTRACTUAL = 'CONTRACTUAL',
  FRAUD = 'FRAUD',
  BANKRUPTCY = 'BANKRUPTCY',
}

export interface LoanDto {
  /** @format uuid */
  id?: string;
  state?: LoanLoanState;
  /** @format uuid */
  customerId?: string;
  /** Currency as defined by ISO 4217 */
  currency?: IsoCurrencyCode;
  principal?: number;
  /** @format date */
  from?: string;
  productType?: ProductType;
  /** @format int32 */
  months?: number;
  /** @format int32 */
  postponedPeriods?: number;
  prolonged?: boolean;
  crowdfunded?: boolean;
  totalRepayment?: number;
  totalPaid?: number;
  outstandingAmount?: number;
  paidPerc?: number;
  instalments?: LoanDtoInstallmentDto[];
  scheduleAgreementState?: ScheduleAgreementState;
  paymentInstruction?: LoanDtoPaymentInstructionDto;
  prolongationEligible?: boolean;
  prolongationFee?: number;
  /** @format int32 */
  maxProlongationPeriodMonths?: number;
  contractUrl?: string;
  /** @format uuid */
  termsId?: string;
  /** @format uuid */
  contractDocId?: string;
  /** @format uuid */
  debtProviderId?: string;
  activeProlongation?: LoanDtoProlongationDto;
}

export interface LoanDtoInstallmentDto {
  /** @format date */
  dueDate?: string;
  /** @format date */
  paidDate?: string;
  total?: number;
  principal?: number;
  fee?: number;
  paidTotal?: number;
  state?: InstallmentInstallmentState;
  /** Currency as defined by ISO 4217 */
  currency?: IsoCurrencyCode;
}

export interface LoanDtoPaymentInstructionDto {
  bban: string;
  iban: string;
  qrCodeString: string;
  reference: string;
  amount: number;
  /** Currency as defined by ISO 4217 */
  currency?: IsoCurrencyCode;
  /** @format date */
  dueDate: string;
}

export interface LoanDtoProlongationDto {
  /** @format uuid */
  id: string;
  state: LoanProlongationState;
  contractSignUrl: string;
}

export enum LoanProlongationOrigin {
  MANUAL = 'MANUAL',
  WEB_APP = 'WEB_APP',
  CALCULATOR = 'CALCULATOR',
  PARTNER_API = 'PARTNER_API',
}

export enum LoanProlongationState {
  NEW = 'NEW',
  PAID = 'PAID',
  ADDENDUM_SENT = 'ADDENDUM_SENT',
  SIGNED = 'SIGNED',
  DONE = 'DONE',
  CANCELED = 'CANCELED',
  ADDENDUM_EXPIRED = 'ADDENDUM_EXPIRED',
}

export type LoanServicingAction = BaseIdEntity &
  BaseMetadataEntity & {
    origin?: LoanServicingActionOrigin;
    type: ServicingActionType;
    note?: string;
  };

export enum LoanServicingActionOrigin {
  MANUAL = 'MANUAL',
  SYSTEM = 'SYSTEM',
}

export interface LoansOverviewDto {
  active?: LoanDto[];
  previous?: LoansOverviewDtoPreviousLoanDto[];
}

export interface LoansOverviewDtoPreviousLoanDto {
  /** @format uuid */
  id?: string;
  state?: LoanLoanState;
  amount?: number;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode;
  /** @format date */
  from?: string;
  /** @format date */
  to?: string;
}

export interface MerchantBankAccountBalance {
  /** Account balance currency as defined by ISO 4217 */
  currency: IsoCurrencyCode;
  /** Account balance amount */
  amount: number;
  /** Account balance type */
  type?: string;
  /**
   * Account balance reference date
   * @format date
   * @example "2020-12-21T00:00:00.000Z"
   */
  referenceDate: string;
}

export interface ModifyOwnerDtoModifyOwnerOrganizationDto {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  regNum: string;
  /** ISO 3166 alpha-2 country code enum, including metadata. */
  countryOfRegistration: IsoCountryCode;
  fop?: boolean;
  legalForm: string;
  court?: string;
  sharePerc?: number;
  /** @format date */
  effectiveFrom: string;
  /** @format date */
  effectiveTo?: string;
  selfDeclaredUbo?: boolean;
  pep?: boolean;
  /** @minLength 1 */
  street: string;
  /** @minLength 1 */
  city: string;
  /** @minLength 1 */
  zip: string;
  /** ISO 3166 alpha-2 country code enum, including metadata. */
  country: IsoCountryCode;
}

export interface ModifyOwnerDtoModifyOwnerPersonDto {
  /** @minLength 1 */
  firstName: string;
  /** @minLength 1 */
  lastName: string;
  /** @format date */
  dateOfBirth: string;
  sharePerc?: number;
  /** @format date */
  effectiveFrom: string;
  /** @format date */
  effectiveTo?: string;
  selfDeclaredUbo?: boolean;
  pep?: boolean;
  /** @minLength 1 */
  street: string;
  /** @minLength 1 */
  city: string;
  /** @minLength 1 */
  zip: string;
  /** ISO 3166 alpha-2 country code enum, including metadata. */
  country: IsoCountryCode;
  nationality?: string;
}

export interface MonthlyAmounts {
  /** @format date */
  date?: string;
  credit?: number;
  debit?: number;
}

export interface NewPartnerRequestDto {
  /** @format uuid */
  customerId?: string;
  platformName?: string;
  platformUrl?: string;
  comment?: string;
}

export interface OfferAdminDto {
  /** @format uuid */
  id: string;
  partnerCode: string;
  merchantId: string;
  state: OfferState;
  type?: OfferType;
  crowdfundingEnabled: boolean;
  published?: boolean;
  /** @format int64 */
  version?: number;
  /** ISO 3166 alpha-2 country code enum, including metadata. */
  country: IsoCountryCode;
}

export interface OfferDto {
  /** @format uuid */
  id: string;
  partnerCode: string;
  merchantId: string;
  state: OfferState;
  /** @deprecated */
  crowdfundingEnabled: boolean;
  products: OfferDtoOfferProductDto[];
  customerWithActiveProduct: boolean;
  published: boolean;
  /** ISO 3166 alpha-2 country code enum, including metadata. */
  country: IsoCountryCode;
}

export interface OfferDtoOfferProductDto {
  product: ProductType;
  ratePerc: number;
  minAmount: number;
  maxAmount: number;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode;
}

export type OfferProduct = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    offerId: string;
    product: ProductType;
    ratePerc: number;
    maxAmount: number;
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    currency: IsoCurrencyCode;
    /** @format int32 */
    riskGrade: number;
    revenueForecast: number;
  };

export enum OfferState {
  NEW = 'NEW',
  SENT = 'SENT',
  LEAD = 'LEAD',
  SUCCESSFUL = 'SUCCESSFUL',
  CANCELLED = 'CANCELLED',
}

export enum OfferType {
  BULK = 'BULK',
  SINGLE = 'SINGLE',
  INDIVIDUAL = 'INDIVIDUAL',
  AGGREGATED = 'AGGREGATED',
}

export enum PartnerPartnerCategory {
  POS = 'POS',
  ECOMMERCE = 'ECOMMERCE',
  OTHER = 'OTHER',
  GASTRO = 'GASTRO',
  PPC = 'PPC',
}

export enum PartnerPartnerType {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export type PartnerConnection = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    customerId?: string;
    partnerCode?: string;
    merchantId?: string;
    tenantId?: string;
    name?: string;
    state?: PartnerConnectionState;
    /** @format date-time */
    connectedAt?: string;
    /** @format date-time */
    disconnectedAt?: string;
    jobState?: PartnerConnectionJobState;
    /** @format date-time */
    jobStartedAt?: string;
    /** @format date-time */
    jobCompletedAt?: string;
    jobErrorMessage?: string;
    /** @format date-time */
    jobUpdatedAt?: string;
    config?: any;
  };

export enum PartnerConnectionJobState {
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export enum PartnerConnectionAdminDtoType {
  SERVICE = 'SERVICE',
  CONNECTION = 'CONNECTION',
}

export interface PartnerConnectionDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  customerId?: string;
  partnerId?: string;
  partnerCode?: string;
  merchantId?: string;
  tenantId?: string;
  state?: PartnerConnectionState;
  productCategory?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  connectedAt?: string;
  /** @format date-time */
  disconnectedAt?: string;
}

export enum PartnerConnectionState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface PartnerDto {
  /** @format uuid */
  id?: string;
  code?: string;
  partyName?: string;
  partyPhone?: string;
  partyEmail?: string;
  category?: PartnerPartnerCategory;
  containerName?: string;
  insightsRevenuesEnabled?: boolean;
  /** @format date-time */
  effectiveFrom?: string;
  /** @format date-time */
  effectiveTo?: string;
  /** @format date-time */
  updatedAt?: string;
}

export interface PartnerLeadDto {
  lead: LeadDto;
  offer: OfferDto;
}

export interface PartnerLeadRequest {
  partnerCode: string;
  merchantId: string;
  tenantId?: string;
  /** ISO 3166 alpha-2 country code enum, including metadata. */
  country?: IsoCountryCode;
  regNum?: string;
  /** @format date-time */
  createdAt?: string;
  signature?: string;
}

export interface PartnerLoansOverviewDto {
  totalInCirculation?: number;
  totalDisbursed?: number;
  disbursedPrincipalAvg?: number;
  expectedCommission?: number;
  totalCommission?: number;
  commissionMonthlyAvg?: number;
  monthlyInCirculation?: PartnerLoansOverviewDtoMonthlyAmountDto[];
  monthlyDisbursed?: PartnerLoansOverviewDtoMonthlyAmountDto[];
  monthlyPaidCommissions?: PartnerLoansOverviewDtoMonthlyAmountDto[];
  monthlyExpectedCommissions?: PartnerLoansOverviewDtoMonthlyAmountDto[];
}

export interface PartnerLoansOverviewDtoMonthlyAmountDto {
  /** @format date */
  date: string;
  amount: number;
}

export interface PartnerOfferOfferProduct {
  /** Product type */
  product: ProductType;
  /** Minimum financing amount for the given product in local currency */
  minAmount: number;
  /** Maximum financing amount for the given product in local currency */
  maxAmount: number;
  /** Currency as defined by ISO 4217 (https://en.wikipedia.org/wiki/ISO_4217) */
  currency: IsoCurrencyCode;
  /** Fee rate percentage [%] */
  ratePerc: number;
  balloon?: boolean;
  /**
   * Total number of installments
   * @format int32
   */
  installmentCount: number;
  /** Indicating whether prolongation is available for this product */
  prolongationEnabled: boolean;
  /** Indicating whether postponement is available for this product */
  postponeEnabled: boolean;
  /**
   * Maximum number of postponed installments
   * @format int32
   */
  maxPostponeLength: number;
  /**
   * Maximum number of installments for prolongation
   * @format int32
   */
  maxProlongationLength: number;
  interestRateMultiplier: number;
  postponeFeeMultiplier: number;
  prolongationFeeMultiplier: number;
}

export interface PartnerOffersOverviewDto {
  /** @format int64 */
  currentCount?: number;
  monthly?: PartnerOffersOverviewDtoMonthlyOffersDto;
}

export interface PartnerOffersOverviewDtoMonthlyOffersDto {
  /** @format date */
  date: string;
  /** @format int32 */
  count: number;
}

export interface PartnerProductParams {
  /** Financing product type */
  type: ProductType;
  /** Financing amount */
  amount: number;
  /** Fee rate percentage [%] */
  ratePerc: number;
  /** Currency as defined by ISO 4217 */
  currency: IsoCurrencyCode;
  /**
   * Postponed months
   * @format int32
   */
  postponedPeriods?: number | null;
  /** Boolean flag for financing prolongation */
  prolonged?: boolean | null;
}

export interface PartnerRequestDto {
  partnerCode?: string;
  fullName?: string;
  companyName?: string;
  phone?: string;
  email?: string;
}

export type PartnerService = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    customerId?: string;
    partnerCode?: string;
    merchantId?: string;
    state?: PartnerServiceState;
    /** @format date-time */
    activatedAt?: string;
    /** @format date-time */
    deactivatedAt?: string;
    config?: any;
    connections?: PartnerConnection[];
  };

export enum PartnerServiceState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export type Party = BaseIdEntity &
  BaseMetadataEntity & {
    orgNameOrRegNum?: string;
    fullName?: string;
    organization?: boolean;
    person?: boolean;
    fopOrganization?: boolean;
    userParty?: boolean;
    partyName?: string;
    externalId?: string;
    type: PartyType;
    /**
     * Two-letter country code as defined by ISO 3166-1 alpha-2 or value '_U' representing a user person
     * @maxLength 2
     * @example "CZ"
     */
    country: string;
    /**
     * @minLength 2
     * @maxLength 2
     */
    nationality?: string;
    /**
     * @format email
     * @maxLength 255
     */
    email?: string;
    /** @maxLength 24 */
    phone?: string;
    /** @format date-time */
    phoneVerifiedAt?: string;
    /** @maxLength 128 */
    name?: string;
    /** @maxLength 128 */
    legalForm?: string;
    /** @format date */
    regSince?: string;
    /** @maxLength 32 */
    regNum?: string;
    /** @maxLength 64 */
    regNum2?: string;
    /** @maxLength 128 */
    regCourt?: string;
    /** @maxLength 32 */
    taxNum?: string;
    /** @maxLength 32 */
    vatNum?: string;
    /** @format date */
    vatSince?: string;
    /** @maxLength 255 */
    web?: string;
    /** @maxLength 34 */
    iban?: string;
    /** @maxLength 64 */
    firstName?: string;
    /** @maxLength 64 */
    lastName?: string;
    /** @format date */
    dob?: string;
    /** @maxLength 32 */
    birthNumber?: string;
    pep?: boolean;
    fop?: boolean;
    consentGiven?: boolean;
    /** @format date-time */
    consentChangedAt?: string;
    segment?: string;
    partnerCode?: string;
    address?: Address;
    sourceType: PartySourceType;
    sourcePriority: PartySourcePriority;
    /** @format date-time */
    registerIdentityAt?: string;
    /** @format date-time */
    registerDataAt?: string;
    activity?: string;
    activityEn?: string;
  };

export interface PartyOrganizationId {
  /** @format uuid */
  partyId: string;
  /** @format uuid */
  organizationId: string;
}

export enum PartySourcePriority {
  UNDEFINED = 'UNDEFINED',
  REGISTER_ALL = 'REGISTER_ALL',
  REGISTER_NON_BLANKS = 'REGISTER_NON_BLANKS',
  LOCAL_ALL = 'LOCAL_ALL',
}

export enum PartySourceType {
  UNDEFINED = 'UNDEFINED',
  REGISTER = 'REGISTER',
  OPERATOR = 'OPERATOR',
  USER = 'USER',
}

export enum PartyType {
  PERSON = 'PERSON',
  ORGANIZATION = 'ORGANIZATION',
}

export interface PartyUnderwritingDto {
  /** @format uuid */
  partyId?: string;
  regNum?: string;
  country?: string;
  partyType?: PartyType;
  fop?: boolean;
  partyLabel?: string;
  /** @format int32 */
  level?: number;
  roleLabel?: string;
  roleContext?: string;
  tags?: RiskProfileTag[];
  severity?: RiskProfileTagSeverity;
}

export interface PaymentInstruction {
  /**
   * Bank account number for installment repayment
   * @maxLength 34
   * @example "CZ6508000000192000145399"
   */
  iban?: string;
  /**
   * Bank account number for installment repayment
   * @maxLength 64
   * @example "19-2000145399/0800"
   */
  bban?: string;
  /**
   * Payment reference (symbol)
   * @maxLength 10
   */
  reference?: string;
  /** Payment amount */
  amount?: number;
  /** Currency as defined by ISO 4217 */
  currency?: IsoCurrencyCode;
  /**
   * Installment due date
   * @format date
   * @example "2020-12-21T00:00:00.000Z"
   */
  dueDate?: string;
}

export interface PersonalProfileDto {
  /** @format uuid */
  id: string;
  /** @format email */
  email: string;
  /** @maxLength 64 */
  firstName: string;
  /** @maxLength 64 */
  lastName: string;
  /** @format date */
  dob: string;
  /**
   * Two-letter country code as defined by ISO 3166-1 alpha-2
   * @minLength 2
   * @maxLength 2
   * @example "CZ"
   */
  country: string;
  address: Address;
  /** @maxLength 24 */
  phone: string;
  /** @format date-time */
  phoneVerifiedAt: string;
  consentGiven: boolean;
  /** @format int64 */
  version: number;
}

export interface ProductParams {
  type?: ProductType;
  amount?: number;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode;
  /** @format int32 */
  postponedPeriods?: number;
  prolonged?: boolean | null;
  crowdfunded?: boolean | null;
}

export interface ProductParams1 {
  type?: ProductType;
  amount?: number;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode;
  ratePerc?: number;
  /** @format int32 */
  postponedPeriods?: number;
  prolonged?: boolean | null;
  crowdfunded?: boolean | null;
  /** @format int32 */
  riskGrade?: number;
}

export interface ProductParams2 {
  type?: ProductType;
  amount?: number;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode;
  ratePerc?: number;
  /** @format int32 */
  postponedPeriods?: number;
  prolonged?: boolean | null;
  crowdfunded?: boolean | null;
  /** @format int32 */
  riskGrade?: number;
}

export interface ProductParams3 {
  type?: ProductType;
  amount?: number;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode;
  ratePerc?: number;
  /** @format int32 */
  postponedPeriods?: number;
  prolonged?: boolean | null;
  crowdfunded?: boolean | null;
  /** @format int32 */
  riskGrade?: number;
}

export enum ProductType {
  M1 = 'M1',
  M3 = 'M3',
  M3R = 'M3R',
  M6 = 'M6',
  M12 = 'M12',
}

export interface ProlongationPreviewDto {
  /** List of installments, representing installment plan valid after prolongation is applied */
  installments?: ProlongationPreviewDtoProlongationPreviewInstallmentDto[];
  /** Total amount of prolongation fee */
  prolongationFeeTotal?: number;
  /** Loan reference */
  externalLoanRef?: string;
  /**
   * Final date when the prolongation payment is due
   * @format date
   */
  prolongationDueDate?: string;
  /** QR code for prolongation payment */
  paymentQrCode?: string;
}

export interface ProlongationPreviewDtoProlongationPreviewInstallmentDto {
  /** Total amount of the installment */
  amount?: number;
  /** Total amount of the installment that has been already paid */
  paidAmount?: number;
  /**
   * Due date of the installment
   * @format date
   */
  dueDate?: string;
  /**
   * If installment was paid, represents paid date
   * @format date
   */
  paidDate?: string;
}

export type PsdAccount = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    customerId?: string;
    externalId?: string;
    bic?: string;
    /** @format uuid */
    bankId?: string;
    iban?: string;
    name?: string;
    ownerName?: string;
    displayName?: string;
    product?: string;
    cashAccountType?: string;
    institutionId?: string;
    providerStatus?: string;
    /** @format date-time */
    accountCreated?: string;
    /** @format date-time */
    accountLastAccessed?: string;
    /** @format date-time */
    firstCompletedReportAt?: string;
    /** @format date-time */
    lastCompletedReportAt?: string;
    /** @format uuid */
    lastCompletedReportConnectionId?: string;
    forceReplaceTransactions?: boolean;
    accountCurrencies?: {
      ADP?: PsdAccountCurrency;
      AED?: PsdAccountCurrency;
      AFA?: PsdAccountCurrency;
      AFN?: PsdAccountCurrency;
      ALL?: PsdAccountCurrency;
      AMD?: PsdAccountCurrency;
      ANG?: PsdAccountCurrency;
      AOA?: PsdAccountCurrency;
      ARS?: PsdAccountCurrency;
      ATS?: PsdAccountCurrency;
      AUD?: PsdAccountCurrency;
      AWG?: PsdAccountCurrency;
      AYM?: PsdAccountCurrency;
      AZM?: PsdAccountCurrency;
      AZN?: PsdAccountCurrency;
      BAM?: PsdAccountCurrency;
      BBD?: PsdAccountCurrency;
      BDT?: PsdAccountCurrency;
      BEF?: PsdAccountCurrency;
      BGL?: PsdAccountCurrency;
      BGN?: PsdAccountCurrency;
      BHD?: PsdAccountCurrency;
      BIF?: PsdAccountCurrency;
      BMD?: PsdAccountCurrency;
      BND?: PsdAccountCurrency;
      BOB?: PsdAccountCurrency;
      BOV?: PsdAccountCurrency;
      BRL?: PsdAccountCurrency;
      BSD?: PsdAccountCurrency;
      BTN?: PsdAccountCurrency;
      BWP?: PsdAccountCurrency;
      BYB?: PsdAccountCurrency;
      BYN?: PsdAccountCurrency;
      BYR?: PsdAccountCurrency;
      BZD?: PsdAccountCurrency;
      CAD?: PsdAccountCurrency;
      CDF?: PsdAccountCurrency;
      CLF?: PsdAccountCurrency;
      CLP?: PsdAccountCurrency;
      CNY?: PsdAccountCurrency;
      COP?: PsdAccountCurrency;
      COU?: PsdAccountCurrency;
      CRC?: PsdAccountCurrency;
      CSD?: PsdAccountCurrency;
      CSK?: PsdAccountCurrency;
      CUC?: PsdAccountCurrency;
      CUP?: PsdAccountCurrency;
      CVE?: PsdAccountCurrency;
      CYP?: PsdAccountCurrency;
      CZK?: PsdAccountCurrency;
      DEM?: PsdAccountCurrency;
      DJF?: PsdAccountCurrency;
      DKK?: PsdAccountCurrency;
      DOP?: PsdAccountCurrency;
      DZD?: PsdAccountCurrency;
      EEK?: PsdAccountCurrency;
      EGP?: PsdAccountCurrency;
      ERN?: PsdAccountCurrency;
      ESP?: PsdAccountCurrency;
      ETB?: PsdAccountCurrency;
      EUR?: PsdAccountCurrency;
      FIM?: PsdAccountCurrency;
      FJD?: PsdAccountCurrency;
      FKP?: PsdAccountCurrency;
      FRF?: PsdAccountCurrency;
      GBP?: PsdAccountCurrency;
      GEL?: PsdAccountCurrency;
      GHC?: PsdAccountCurrency;
      GHS?: PsdAccountCurrency;
      GIP?: PsdAccountCurrency;
      GMD?: PsdAccountCurrency;
      GNF?: PsdAccountCurrency;
      GRD?: PsdAccountCurrency;
      GTQ?: PsdAccountCurrency;
      GWP?: PsdAccountCurrency;
      GYD?: PsdAccountCurrency;
      HKD?: PsdAccountCurrency;
      HNL?: PsdAccountCurrency;
      HRK?: PsdAccountCurrency;
      HTG?: PsdAccountCurrency;
      HUF?: PsdAccountCurrency;
      CHE?: PsdAccountCurrency;
      CHF?: PsdAccountCurrency;
      CHW?: PsdAccountCurrency;
      IDR?: PsdAccountCurrency;
      IEP?: PsdAccountCurrency;
      ILS?: PsdAccountCurrency;
      INR?: PsdAccountCurrency;
      IQD?: PsdAccountCurrency;
      IRR?: PsdAccountCurrency;
      ISK?: PsdAccountCurrency;
      ITL?: PsdAccountCurrency;
      JMD?: PsdAccountCurrency;
      JOD?: PsdAccountCurrency;
      JPY?: PsdAccountCurrency;
      KES?: PsdAccountCurrency;
      KGS?: PsdAccountCurrency;
      KHR?: PsdAccountCurrency;
      KMF?: PsdAccountCurrency;
      KPW?: PsdAccountCurrency;
      KRW?: PsdAccountCurrency;
      KWD?: PsdAccountCurrency;
      KYD?: PsdAccountCurrency;
      KZT?: PsdAccountCurrency;
      LAK?: PsdAccountCurrency;
      LBP?: PsdAccountCurrency;
      LKR?: PsdAccountCurrency;
      LRD?: PsdAccountCurrency;
      LSL?: PsdAccountCurrency;
      LTL?: PsdAccountCurrency;
      LUF?: PsdAccountCurrency;
      LVL?: PsdAccountCurrency;
      LYD?: PsdAccountCurrency;
      MAD?: PsdAccountCurrency;
      MDL?: PsdAccountCurrency;
      MGA?: PsdAccountCurrency;
      MGF?: PsdAccountCurrency;
      MKD?: PsdAccountCurrency;
      MMK?: PsdAccountCurrency;
      MNT?: PsdAccountCurrency;
      MOP?: PsdAccountCurrency;
      MRO?: PsdAccountCurrency;
      MRU?: PsdAccountCurrency;
      MTL?: PsdAccountCurrency;
      MUR?: PsdAccountCurrency;
      MVR?: PsdAccountCurrency;
      MWK?: PsdAccountCurrency;
      MXN?: PsdAccountCurrency;
      MXV?: PsdAccountCurrency;
      MYR?: PsdAccountCurrency;
      MZM?: PsdAccountCurrency;
      MZN?: PsdAccountCurrency;
      NAD?: PsdAccountCurrency;
      NGN?: PsdAccountCurrency;
      NIO?: PsdAccountCurrency;
      NLG?: PsdAccountCurrency;
      NOK?: PsdAccountCurrency;
      NPR?: PsdAccountCurrency;
      NZD?: PsdAccountCurrency;
      OMR?: PsdAccountCurrency;
      PAB?: PsdAccountCurrency;
      PEN?: PsdAccountCurrency;
      PGK?: PsdAccountCurrency;
      PHP?: PsdAccountCurrency;
      PKR?: PsdAccountCurrency;
      PLN?: PsdAccountCurrency;
      PTE?: PsdAccountCurrency;
      PYG?: PsdAccountCurrency;
      QAR?: PsdAccountCurrency;
      ROL?: PsdAccountCurrency;
      RON?: PsdAccountCurrency;
      RSD?: PsdAccountCurrency;
      RUB?: PsdAccountCurrency;
      RUR?: PsdAccountCurrency;
      RWF?: PsdAccountCurrency;
      SAR?: PsdAccountCurrency;
      SBD?: PsdAccountCurrency;
      SCR?: PsdAccountCurrency;
      SDD?: PsdAccountCurrency;
      SDG?: PsdAccountCurrency;
      SEK?: PsdAccountCurrency;
      SGD?: PsdAccountCurrency;
      SHP?: PsdAccountCurrency;
      SIT?: PsdAccountCurrency;
      SKK?: PsdAccountCurrency;
      SLE?: PsdAccountCurrency;
      SLL?: PsdAccountCurrency;
      SOS?: PsdAccountCurrency;
      SRD?: PsdAccountCurrency;
      SRG?: PsdAccountCurrency;
      SSP?: PsdAccountCurrency;
      STD?: PsdAccountCurrency;
      STN?: PsdAccountCurrency;
      SVC?: PsdAccountCurrency;
      SYP?: PsdAccountCurrency;
      SZL?: PsdAccountCurrency;
      THB?: PsdAccountCurrency;
      TJS?: PsdAccountCurrency;
      TMM?: PsdAccountCurrency;
      TMT?: PsdAccountCurrency;
      TND?: PsdAccountCurrency;
      TOP?: PsdAccountCurrency;
      TPE?: PsdAccountCurrency;
      TRL?: PsdAccountCurrency;
      TRY?: PsdAccountCurrency;
      TTD?: PsdAccountCurrency;
      TWD?: PsdAccountCurrency;
      TZS?: PsdAccountCurrency;
      UAH?: PsdAccountCurrency;
      UGX?: PsdAccountCurrency;
      USD?: PsdAccountCurrency;
      USN?: PsdAccountCurrency;
      USS?: PsdAccountCurrency;
      UYI?: PsdAccountCurrency;
      UYU?: PsdAccountCurrency;
      UYW?: PsdAccountCurrency;
      UZS?: PsdAccountCurrency;
      VEB?: PsdAccountCurrency;
      VED?: PsdAccountCurrency;
      VEF?: PsdAccountCurrency;
      VES?: PsdAccountCurrency;
      VND?: PsdAccountCurrency;
      VUV?: PsdAccountCurrency;
      WST?: PsdAccountCurrency;
      XAF?: PsdAccountCurrency;
      XAG?: PsdAccountCurrency;
      XAU?: PsdAccountCurrency;
      XBA?: PsdAccountCurrency;
      XBB?: PsdAccountCurrency;
      XBC?: PsdAccountCurrency;
      XBD?: PsdAccountCurrency;
      XCD?: PsdAccountCurrency;
      XDR?: PsdAccountCurrency;
      XFO?: PsdAccountCurrency;
      XFU?: PsdAccountCurrency;
      XOF?: PsdAccountCurrency;
      XPD?: PsdAccountCurrency;
      XPF?: PsdAccountCurrency;
      XPT?: PsdAccountCurrency;
      XSU?: PsdAccountCurrency;
      XTS?: PsdAccountCurrency;
      XUA?: PsdAccountCurrency;
      XXX?: PsdAccountCurrency;
      YER?: PsdAccountCurrency;
      YUM?: PsdAccountCurrency;
      ZAR?: PsdAccountCurrency;
      ZMK?: PsdAccountCurrency;
      ZMW?: PsdAccountCurrency;
      ZWD?: PsdAccountCurrency;
      ZWL?: PsdAccountCurrency;
      ZWN?: PsdAccountCurrency;
      ZWR?: PsdAccountCurrency;
    };
  };

export type PsdAccountCurrency = BaseMetadataEntity & {
  /** @format uuid */
  accountId: string;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode;
  balance?: number;
  balanceType?: string;
  /** @format date */
  balanceReferenceDate?: string;
  /** @format date-time */
  balanceUpdatedAt?: string;
  periodBins?: any;
  transactions?: PsdTransaction[];
};

export interface PsdAccountPreview {
  /** @format uuid */
  id?: string;
  iban?: string;
  ownerName?: string;
}

export type PsdConnection = BaseIdEntity &
  BaseMetadataEntity & {
    syncState?: PsdConnectionSyncState;
    /** @format uuid */
    customerId?: string;
    /** @format uuid */
    applicationId?: string;
    state?: PsdConnectionState;
    provider?: PsdConnectionProvider;
    customerRef?: string;
    connectionRef?: string;
    holderInfoSupported?: boolean;
    /** @format date-time */
    firstCompletedReportAt?: string;
    /** @format date-time */
    lastCompletedReportAt?: string;
    /** @format date-time */
    lastErrorAt?: string;
    /** @format int32 */
    errorCount?: number;
    errorMessage?: string;
    /** @format date-time */
    deferredTo?: string;
    iban?: string;
    /** @format uuid */
    bankId?: string;
    /** @format date */
    consentValidTo?: string;
    /** @format date-time */
    reminder1At?: string;
    /** @format date-time */
    reminder2At?: string;
    /** @format date-time */
    reportRequestedAt?: string;
    forceReplaceTransactions?: boolean;
  };

export interface PsdConnectionDto {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  customerId?: string;
  connectionRef?: string;
  state?: PsdConnectionState;
  iban?: string;
  /** @format uuid */
  bankId?: string;
  /** @format date */
  consentValidTo?: string;
  url?: string;
}

export enum PsdConnectionProvider {
  NORDIGEN = 'NORDIGEN',
  SHOPTET = 'SHOPTET',
  PARTNER_PUSH = 'PARTNER_PUSH',
  PARTNER_PULL = 'PARTNER_PULL',
}

export enum PsdConnectionState {
  PENDING = 'PENDING',
  ACTIVATING = 'ACTIVATING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  FAILED = 'FAILED',
  UNSUPPORTED = 'UNSUPPORTED',
}

export enum PsdConnectionSyncState {
  SCHEDULED = 'SCHEDULED',
  RETRY_SCHEDULED = 'RETRY_SCHEDULED',
  PROCESSING = 'PROCESSING',
  UP_TO_DATE = 'UP_TO_DATE',
  OUTDATED = 'OUTDATED',
}

export interface PsdPeriodBin {
  saldo?: number;
  /** @format uuid */
  accountId: string;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode;
  periodType: PsdPeriodType;
  /** @format int32 */
  periodId: number;
  /** @format int32 */
  creditCount?: number;
  creditSum?: number;
  /** @format int32 */
  debitCount?: number;
  debitSum?: number;
}

export enum PsdPeriodType {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export type PsdTransaction = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    connectionId?: string;
    /** @format uuid */
    accountId: string;
    accountExternalId?: string;
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    currency: IsoCurrencyCode;
    direction: PsdTransactionDirection;
    /** @format date */
    valueDate?: string;
    /** @format date */
    bookingDate: string;
    amount?: number;
    counterAccount?: string;
    counterName?: string;
    remittanceInfo?: string;
    endToEndId?: string;
    vs?: string;
    ss?: string;
    ks?: string;
    externalId?: string;
    externalId2?: string;
    externalId3?: string;
    externalData?: string;
    /** @format int32 */
    rank?: number;
    dirty?: boolean;
  };

export enum PsdTransactionDirection {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export interface RecurrentTransactions {
  counterName?: string;
  counterAccount?: string;
  amount?: number;
  /** @format int64 */
  txCount?: number;
  txAmountSum?: number;
  remittanceInfo?: string;
}

export interface RegisterInfo {
  state?: RegisterInfoState;
  normalizedRegNum?: string;
  organization?: RegisterInfoOrganization;
}

export interface RegisterInfoOrganization {
  country?: string;
  name?: string;
  regNum?: string;
  taxNum?: string;
  vatNum?: string;
  address?: Address;
  legalForm?: string;
  /** @format date */
  regSince?: string;
  regCourt?: string;
  fop?: boolean;
}

export enum RegisterInfoState {
  VALID_REGNUM = 'VALID_REGNUM',
  EMPTY_COUNTRY = 'EMPTY_COUNTRY',
  UNSUPPORTED_COUNTRY = 'UNSUPPORTED_COUNTRY',
  EMPTY_REGNUM = 'EMPTY_REGNUM',
  INVALID_REGNUM_FORMAT = 'INVALID_REGNUM_FORMAT',
  NONEXISTING_REGNUM = 'NONEXISTING_REGNUM',
  INACTIVE_COMPANY = 'INACTIVE_COMPANY',
  DUPLICIT_REGNUM = 'DUPLICIT_REGNUM',
  REGISTER_LOOKUP_FAILED = 'REGISTER_LOOKUP_FAILED',
}

export enum RelPartyUserPartyState {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

export enum RelTxnInstallmentRepaymentType {
  PRINCIPAL = 'PRINCIPAL',
  FEE = 'FEE',
  PROLONGATION = 'PROLONGATION',
  PENALTY = 'PENALTY',
  FEEREMINDER1 = 'FEE_REMINDER1',
  FEEREMINDER2 = 'FEE_REMINDER2',
  NOTARY_FEE = 'NOTARY_FEE',
}

export interface RepresentativeDto {
  /** @format uuid */
  id: string;
  /** @format uuid */
  organizationId: string;
  firstName: string;
  lastName: string;
  /**
   * Date of birth
   * @format date
   * @example "2020-12-21T00:00:00.000Z"
   */
  dob?: string;
  address: Address;
  email: string;
  phone: string;
  roleText: string;
  statutory: boolean;
  /** @format date-time */
  effectiveFrom: string;
  /** @format date-time */
  effectiveTo: string;
}

export interface RepresentativeDtoRelUserParty {
  userParty: Party;
  state: RelPartyUserPartyState;
  /** @format date-time */
  stateUpdatedAt: string;
  /** @format uuid */
  stateUpdatedBy: string;
}

export interface RevenueDto {
  previous?: RevenueDtoMonthlyRevenueDto;
  next?: RevenueDtoMonthlyRevenueDto;
}

export interface RevenueDtoDailyRevenueDto {
  branchId?: string;
  /** @format date */
  date?: string;
  revenue?: number;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode;
  isForecast?: boolean;
}

export interface RevenueDtoMonthlyRevenueDto {
  dailyRevenues?: RevenueDtoDailyRevenueDto[];
}

export interface RevenuesReportDtoRevenue {
  revenue?: number;
  isForecast?: boolean;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode;
}

export interface RevenuesReportDtoSummary {
  monthlyRevenues?: any;
  yearlyRevenues?: any;
}

export interface RevenuesReportDtoYearlyData {
  /** @format int32 */
  year?: number;
  yearlyPartnerRevenues?: any;
  quarterlyPartnerRevenues?: any;
}

export interface RevenuesReportDtoYearlySummaryData {
  yearlyRevenue?: RevenuesReportDtoRevenue;
  quarterlyRevenues?: any;
}

export type RiskEvaluation = BaseIdEntity &
  BaseMetadataEntity & {
    /** @format uuid */
    customerId?: string;
    partnerCode?: string;
    merchantId?: string;
    type: RiskEvaluationType;
    /** ISO 3166 alpha-2 country code enum, including metadata. */
    country?: IsoCountryCode;
    businessIdHash?: string;
    grades?: RiskEvaluationGrade[];
    /** @format date */
    observationDate?: string;
    /** @format uuid */
    batchId?: string | null;
    /** @format date */
    firstTransactionDate?: string;
    /** @format date */
    lastTransactionDate?: string;
  };

export type RiskEvaluationGrade = BaseMetadataEntity & {
  product: ProductType;
  /** @format uuid */
  riskEvaluationId: string;
  /** @format int32 */
  riskGrade?: number;
  forecastedRevenue?: number;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode;
  predictedPd?: number;
  modelId?: string;
  partial_scores?: any;
};

export enum RiskEvaluationType {
  BUSINESS_DATA = 'BUSINESS_DATA',
  OPEN_BANKING = 'OPEN_BANKING',
  SENTIMENT = 'SENTIMENT',
}

export interface RiskGradeDto {
  /** @format int32 */
  riskGradePartner?: number;
  /** @format int32 */
  forecastedRevenue?: number;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency?: IsoCurrencyCode;
}

export interface RiskGradeSummary {
  partnerProductSummaries?: any;
  aggregatedProductSummary?: {
    M1?: AggregatedRiskGradeDto;
    M3?: AggregatedRiskGradeDto;
    M3R?: AggregatedRiskGradeDto;
    M6?: AggregatedRiskGradeDto;
    M12?: AggregatedRiskGradeDto;
  };
}

export type RiskProfile = BaseIdEntity &
  BaseMetadataEntity & {
    tagsArray?: string[];
    /** @format uuid */
    partyId: string;
    /** @format uuid */
    customerScoringId?: string;
    /** @format date-time */
    validAt: string;
    capital?: number;
    /** ISO 4217 alpha-3 currency code enum, including metadata. */
    capitalCurrency?: IsoCurrencyCode;
    vatPayer?: boolean;
    /** @format date */
    vatPayerSince?: string;
    /** @format int32 */
    employeeCountFrom?: number;
    /** @format int32 */
    employeeCountTo?: number;
    /** @format int32 */
    employeeCountCode?: number;
    /** @format date */
    employeeCountAt?: string;
    hasPremises?: boolean;
    bankAccounts?: string[];
    tags?: RiskProfileTag[];
  };

export enum RiskProfileTag {
  UNRELIABLE_PAYER = 'UNRELIABLE_PAYER',
  RESIDENCE_AT_TOWN_HALL = 'RESIDENCE_AT_TOWN_HALL',
  VIRTUAL_RESIDENCY = 'VIRTUAL_RESIDENCY',
  INSOLVENCY_RISK = 'INSOLVENCY_RISK',
  INSOLVENCY_RISK_OF_ANOTHER_COMPANY = 'INSOLVENCY_RISK_OF_ANOTHER_COMPANY',
  INSOLVENCY = 'INSOLVENCY',
  INSOLVENCY_OF_ANOTHER_COMPANY = 'INSOLVENCY_OF_ANOTHER_COMPANY',
  EXECUTION = 'EXECUTION',
  EXECUTION_OF_ANOTHER_COMPANY = 'EXECUTION_OF_ANOTHER_COMPANY',
  LIQUIDATION = 'LIQUIDATION',
  BANKRUPTCY = 'BANKRUPTCY',
  CANCELED_COMPANY = 'CANCELED_COMPANY',
  CRIMINAL_RECORD = 'CRIMINAL_RECORD',
  DEBT_INSTITUTION = 'DEBT_INSTITUTION',
  BLACKLISTED = 'BLACKLISTED',
  BLACKLIST_OF_ANOTHER_COMPANY = 'BLACKLIST_OF_ANOTHER_COMPANY',
  COMPANY_NAME = 'COMPANY_NAME',
  VAT_PAYER_NO_BANK_ACCOUNT = 'VAT_PAYER_NO_BANK_ACCOUNT',
  NOOWNEROVER30PERCENT = 'NO_OWNER_OVER_30_PERCENT',
  INSUFFICIENT_OWNERSHIP_COVERAGE = 'INSUFFICIENT_OWNERSHIP_COVERAGE',
  APPLICANTS_VS_CREDITCHECK_MISMATCH = 'APPLICANTS_VS_CREDITCHECK_MISMATCH',
  APPLICANTS_VS_DOCUMENTS_MISMATCH = 'APPLICANTS_VS_DOCUMENTS_MISMATCH',
  PEP_APPLICANT = 'PEP_APPLICANT',
  NO_WORKPLACE = 'NO_WORKPLACE',
  EXECUTION_RISK = 'EXECUTION_RISK',
  EXECUTION_RISK_OF_ANOTHER_COMPANY = 'EXECUTION_RISK_OF_ANOTHER_COMPANY',
  BANK_ACCOUNT_DATA = 'BANK_ACCOUNT_DATA',
  BANK_ACCOUNT_HOLDER = 'BANK_ACCOUNT_HOLDER',
  NEW_COMPANY = 'NEW_COMPANY',
  COMPANYOWNSMORETHAN50PERCENT = 'COMPANY_OWNS_MORE_THAN_50_PERCENT',
  CREDIT_CHECK_ERROR = 'CREDIT_CHECK_ERROR',
  DISTRAINT_CHECK_ERROR = 'DISTRAINT_CHECK_ERROR',
  INCOMPLETE_REPRESENTATIVE_KEY = 'INCOMPLETE_REPRESENTATIVE_KEY',
  INCOMPLETE_OWNER_KEY = 'INCOMPLETE_OWNER_KEY',
  MISSING_REGISTER_ENTRY = 'MISSING_REGISTER_ENTRY',
  INCOMPLETE_REGISTER_COVERAGE = 'INCOMPLETE_REGISTER_COVERAGE',
  COLLATERAL_SHARE = 'COLLATERAL_SHARE',
  CANCELATION_RISK = 'CANCELATION_RISK',
  HISTORICAL_DEBT_INSTITUTION = 'HISTORICAL_DEBT_INSTITUTION',
  INCOMPLETE_DISTRAINT_CHECK_KEY = 'INCOMPLETE_DISTRAINT_CHECK_KEY',
  INCOMPLETE_DISTRAINT_CHECK_COVERAGE = 'INCOMPLETE_DISTRAINT_CHECK_COVERAGE',
  VAT_ACCOUNT_NOT_CONNECTED = 'VAT_ACCOUNT_NOT_CONNECTED',
  FOREIGNER = 'FOREIGNER',
  BUSINESS_DATA_OLDER_THAN_ESTABLISHMENT = 'BUSINESS_DATA_OLDER_THAN_ESTABLISHMENT',
  OPEN_BANKING_PIPELINE_ERROR = 'OPEN_BANKING_PIPELINE_ERROR',
  INCOMPLETE_STATUTORY_STRUCTURE = 'INCOMPLETE_STATUTORY_STRUCTURE',
  MANUAL_OR_OUTDATED_STATUTORIES = 'MANUAL_OR_OUTDATED_STATUTORIES',
  CREDIT_CHECKED = 'CREDIT_CHECKED',
  DISTRAINT_CHECKED = 'DISTRAINT_CHECKED',
  HAS_WORKPLACE = 'HAS_WORKPLACE',
  HAS_PROPERTY = 'HAS_PROPERTY',
  REP_HAS_PROPERTY = 'REP_HAS_PROPERTY',
  CANCELED_VAT_REGISTRATION = 'CANCELED_VAT_REGISTRATION',
  OPEN_BANKING_PIPELINE_NO_DATA = 'OPEN_BANKING_PIPELINE_NO_DATA',
  MANUAL_OR_OUTDATED_STATUTORY = 'MANUAL_OR_OUTDATED_STATUTORY',
  COMPANY_STATUTORIES = 'COMPANY_STATUTORIES',
}

export enum RiskProfileTagSeverity {
  NONE = 'NONE',
  INFO = 'INFO',
  MANUAL_CHECK = 'MANUAL_CHECK',
  KO = 'KO',
}

export interface ScheduleAgreementInstallmentDto {
  /** @format date */
  dueDate: string;
  principal: number;
  capitalizedInterest: number;
  fee: number;
  notaryFee: number;
  type: InstallmentType;
}

export enum ScheduleAgreementState {
  NEW = 'NEW',
  CONFIRMED = 'CONFIRMED',
  EMAIL_EXPIRED = 'EMAIL_EXPIRED',
  PAID = 'PAID',
  OVERDUE = 'OVERDUE',
  ADDENDUM_SENT = 'ADDENDUM_SENT',
  ADDENDUM_EXPIRED = 'ADDENDUM_EXPIRED',
  NOTARY_RECORD = 'NOTARY_RECORD',
  DONE = 'DONE',
  CANCELED = 'CANCELED',
}

export interface ScoreCard {
  score?: number;
  scoreDetail?: any;
  /** @format date-time */
  scoreAt?: string;
}

export interface ServiceActivationRequestActivationUbo {
  /**
   * First name
   * @minLength 1
   * @maxLength 64
   */
  firstName: string;
  /**
   * Last name
   * @minLength 1
   * @maxLength 64
   */
  surname: string;
  /**
   * Date of birth
   * @format date
   * @example "1990-01-01T00:00:00.000Z"
   */
  dateOfBirth: string;
  /**
   * UBO's nationality, country code as defined by ISO 3166-1 alpha-2
   * @minLength 2
   * @maxLength 2
   * @example "CZ"
   */
  nationality: string;
  /**
   * Street address
   * @minLength 1
   */
  street: string;
  /**
   * Name of the city
   * @minLength 1
   */
  city: string;
  /**
   * ZIP code
   * @minLength 1
   */
  zip: string;
  /**
   * UBO's country of residence corresponding to the provided address. Country code as defined by ISO 3166-1 alpha-2
   * @minLength 2
   * @maxLength 2
   * @example "CZ"
   */
  countryOfResidence: string;
  /** PEP declaration */
  pepDeclaration: boolean;
}

export interface ServiceActivationRequestCompany {
  /**
   * Identification number of the company in business register
   * @maxLength 32
   */
  businessId: string;
  /**
   * Two-letter country code as defined by ISO 3166-1 alpha-2
   * @minLength 2
   * @maxLength 2
   * @example "CZ"
   */
  country: string;
  /**
   * Bank account number for financing disbursement
   * @maxLength 34
   */
  iban?: string;
  /**
   * URL of company's website
   * @maxLength 128
   */
  web?: string;
  /**
   * Email address
   * @format email
   */
  email: string;
  /**
   * Phone number in international format E.123
   * @maxLength 24
   */
  phone?: string;
  /**
   * Product category
   * @maxLength 256
   */
  productCategory?: string;
}

export enum ServicingActionType {
  RESCHEDULED = 'RESCHEDULED',
  RESTRUCTURED = 'RESTRUCTURED',
  DEFERRED1 = 'DEFERRED1',
  DEFERRED2 = 'DEFERRED2',
  INSOLVENCY = 'INSOLVENCY',
  FORECLOSURE = 'FORECLOSURE',
  MANUAL_DEFERMENT = 'MANUAL_DEFERMENT',
  DEFAULT = 'DEFAULT',
}

export type SetRiskProfileTag = CollectionRiskProfileTag & {
  empty?: boolean;
};

export interface Sort {
  orderBy: SortOrder[];
}

export interface SortOrder {
  ignoreCase: boolean;
  direction: SortOrderDirection;
  property: string;
  ascending?: boolean;
}

export enum SortOrderDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface TestResourceBenchmarkRecord {
  /** @format int64 */
  calls?: number;
  /** @format double */
  seconds?: number;
  /** @format double */
  callsPerSecond?: number;
  /** @format double */
  secondsPerCall?: number;
}

export enum TrancheInterestCalculationPlanDayCountConvention {
  ACT360 = 'ACT_360',
  ACT365 = 'ACT_365',
}

export enum TrancheInterestCalculationPlanTrancheInterestCalcBase {
  CAP_AMOUNT = 'CAP_AMOUNT',
  ALLOCATED_AMOUNT = 'ALLOCATED_AMOUNT',
}

export enum TrancheInterestPaymentType {
  SYSTEM = 'SYSTEM',
  MANUAL = 'MANUAL',
}

export enum TrancheTxnType {
  DRAWDOWN = 'DRAWDOWN',
  LOAN_DISBURSEMENT = 'LOAN_DISBURSEMENT',
  LOAN_DISBURSEMENT_CANCELATION = 'LOAN_DISBURSEMENT_CANCELATION',
  BUYBACK_DPD_CREDIT = 'BUYBACK_DPD_CREDIT',
  BUYBACK_DPD_DEBIT = 'BUYBACK_DPD_DEBIT',
  BUYBACK_RESTRUCTURE_CREDIT = 'BUYBACK_RESTRUCTURE_CREDIT',
  BUYBACK_RESTRUCTURE_DEBIT = 'BUYBACK_RESTRUCTURE_DEBIT',
  BUYBACK_BANKRUPTCY_CREDIT = 'BUYBACK_BANKRUPTCY_CREDIT',
  BUYBACK_BANKRUPTCY_DEBIT = 'BUYBACK_BANKRUPTCY_DEBIT',
  BUYBACK_FRAUD_CREDIT = 'BUYBACK_FRAUD_CREDIT',
  BUYBACK_FRAUD_DEBIT = 'BUYBACK_FRAUD_DEBIT',
  MANUAL_CORRECTION = 'MANUAL_CORRECTION',
  REALLOCATION_CREDIT = 'REALLOCATION_CREDIT',
  REALLOCATION_DEBIT = 'REALLOCATION_DEBIT',
  INSTALLMENT_PRINCIPAL = 'INSTALLMENT_PRINCIPAL',
  INSTALLMENT_FEE = 'INSTALLMENT_FEE',
  INSTALLMENT_FEE_PROLONGATION = 'INSTALLMENT_FEE_PROLONGATION',
  INSTALLMENT_FEE_PENALTY = 'INSTALLMENT_FEE_PENALTY',
  INSTALLMENTFEEREMINDER1 = 'INSTALLMENT_FEE_REMINDER1',
  INSTALLMENTFEEREMINDER2 = 'INSTALLMENT_FEE_REMINDER2',
  INSTALLMENT_FEE_NOTARY = 'INSTALLMENT_FEE_NOTARY',
  INTEREST = 'INTEREST',
  PRINCIPAL = 'PRINCIPAL',
}

export enum TransactionDirection {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export enum TxnManualPairingDtoDebtProviderTargetType {
  DRAWDOWN = 'DRAWDOWN',
}

export enum TxnManualPairingDtoPairingTarget {
  DEBT_PROVIDER = 'DEBT_PROVIDER',
  LOAN = 'LOAN',
}

export interface UpdateCustomerDtoAddressDto {
  /** @maxLength 64 */
  line1?: string | null;
  /** @maxLength 64 */
  line2?: string | null;
  /** @maxLength 48 */
  city?: string | null;
  /** @maxLength 16 */
  zip?: string | null;
  /**
   * @minLength 2
   * @maxLength 2
   */
  country?: string | null;
}

export interface UpdateCustomerRepDtoAddressDto {
  /** @maxLength 64 */
  line1?: string | null;
  /** @maxLength 64 */
  line2?: string | null;
  /** @maxLength 48 */
  city?: string | null;
  /** @maxLength 16 */
  zip?: string | null;
  /**
   * @minLength 2
   * @maxLength 2
   */
  country?: string | null;
}

export interface UpdateCustomerRepDtoRegDto {
  roleText: string;
  statutory: boolean;
  /** @format date */
  effectiveFrom: string;
  /** @format date */
  effectiveTo: string;
  intermediatePartyNames: string[];
}

export interface UpdateCustomerRepDtoUserDto {
  /**
   * @format email
   * @maxLength 255
   */
  email: string;
  /** @maxLength 24 */
  phone: string;
  pep: boolean;
}

export interface UpdateOfferDto {
  /** @minLength 1 */
  partnerCode: string;
  /** @minLength 1 */
  merchantId: string;
  /** @minItems 1 */
  products: UpdateOfferDtoUpdateOfferProductDto[];
}

export interface UpdateOfferDtoUpdateOfferProductDto {
  product: ProductType;
  ratePerc: number;
  maxAmount: number;
  /** ISO 4217 alpha-3 currency code enum, including metadata. */
  currency: IsoCurrencyCode;
  /**
   * @format int32
   * @min 1
   * @max 5
   */
  riskGrade: number;
  revenueForecast: number;
}

export interface UpdatePersonalProfileDto {
  /** @maxLength 64 */
  firstName?: string;
  /** @maxLength 64 */
  lastName?: string;
  /** @format date */
  dob?: string;
  address?: Address;
  /** @format int64 */
  version?: number;
}

export type User = BaseIdEntity &
  BaseMetadataEntity & {
    type: UserType;
    /** @maxLength 64 */
    authId: string;
    /** @maxLength 64 */
    username: string;
    signupContext?: string | null;
    invited: boolean;
  };

export enum UserType {
  CUSTOMER = 'CUSTOMER',
  PARTNER = 'PARTNER',
  FLOWPAY = 'FLOWPAY',
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'https://api.my.flowpay.io';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => 'undefined' !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string'
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {}),
        },
        signal:
          (cancelToken
            ? this.createAbortSignal(cancelToken)
            : requestParams.signal) || null,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title myFlowpay API
 * @version 1.0.0
 * @termsOfService https://flowpay.io/en/terms
 * @baseUrl https://api.my.flowpay.io
 * @contact Flowpay <info@flowpay.io> (https://flowpay.io)
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags myFlowpay, myFlowpay.Applications
     * @name CreateApplication
     * @summary Create new loan application
     * @request POST:/api/v1/applications
     * @secure
     */
    createApplication: (
      data: ApplicationCreateDto,
      params: RequestParams = {}
    ) =>
      this.request<Application, any>({
        path: `/api/v1/applications`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name GetActiveApplicationForCustomer
     * @summary Get an active application for the given customer
     * @request GET:/api/v1/applications/active/customer/{id}
     * @secure
     */
    getActiveApplicationForCustomer: (id: string, params: RequestParams = {}) =>
      this.request<ActiveApplicationDto, any>({
        path: `/api/v1/applications/active/customer/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name AcceptChangedTerms
     * @summary Accept changed terms of financing
     * @request POST:/api/v1/applications/{id}/accept-changed-terms
     * @secure
     */
    acceptChangedTerms: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/applications/${id}/accept-changed-terms`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCustomerRepresentativesFor2ndApplicant
     * @summary Get a list of representatives suitable for 2nd applicant
     * @request GET:/api/v1/applications/{id}/customer-representatives
     * @secure
     */
    getCustomerRepresentativesFor2ndApplicant: (
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<RepresentativeDto[], any>({
        path: `/api/v1/applications/${id}/customer-representatives`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SubmitApplicationBank
     * @summary Submit loan application - step BANK
     * @request POST:/api/v1/applications/{id}/step/bank
     * @secure
     */
    submitApplicationBank: (
      id: string,
      data: ApplicationSubmitBankDto,
      params: RequestParams = {}
    ) =>
      this.request<Application, any>({
        path: `/api/v1/applications/${id}/step/bank`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SubmitApplicationKycFinish
     * @summary Submit loan application - step KYC finish
     * @request POST:/api/v1/applications/{id}/step/kyc/finish/person/{personId}
     * @secure
     */
    submitApplicationKycFinish: (
      id: string,
      personId: string,
      params: RequestParams = {}
    ) =>
      this.request<Application, any>({
        path: `/api/v1/applications/${id}/step/kyc/finish/person/${personId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SubmitApplicationKycStart
     * @summary Submit loan application - step KYC start a given representative
     * @request POST:/api/v1/applications/{id}/step/kyc/start/person/{personId}
     * @secure
     */
    submitApplicationKycStart: (
      id: string,
      personId: string,
      params: RequestParams = {}
    ) =>
      this.request<ApplicationSubmitKycDto, any>({
        path: `/api/v1/applications/${id}/step/kyc/start/person/${personId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SubmitApplicationPep
     * @summary Submit loan application - step PEP
     * @request POST:/api/v1/applications/{id}/step/pep
     * @secure
     */
    submitApplicationPep: (
      id: string,
      data: ApplicationSubmitPepDto,
      params: RequestParams = {}
    ) =>
      this.request<Application, any>({
        path: `/api/v1/applications/${id}/step/pep`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SubmitApplicationPsdComplete
     * @summary Submit loan application - step PSD complete
     * @request POST:/api/v1/applications/{id}/step/psd/complete
     * @secure
     */
    submitApplicationPsdComplete: (id: string, params: RequestParams = {}) =>
      this.request<ApplicationSubmitPsdDto, any>({
        path: `/api/v1/applications/${id}/step/psd/complete`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SubmitApplicationPsdStart
     * @summary Submit loan application - step PSD start
     * @request POST:/api/v1/applications/{id}/step/psd/start
     * @secure
     */
    submitApplicationPsdStart: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/v1/applications/${id}/step/psd/start`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SubmitApplicationRep2Custom
     * @summary Submit loan application - step REP2
     * @request POST:/api/v1/applications/{id}/step/rep2
     * @secure
     */
    submitApplicationRep2Custom: (
      id: string,
      data: ApplicationSubmitRep2DtoCustom,
      params: RequestParams = {}
    ) =>
      this.request<Application, any>({
        path: `/api/v1/applications/${id}/step/rep2`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SubmitApplicationRep2None
     * @summary Submit loan application - step REP2 - none
     * @request POST:/api/v1/applications/{id}/step/rep2/none
     * @secure
     */
    submitApplicationRep2None: (id: string, params: RequestParams = {}) =>
      this.request<Application, any>({
        path: `/api/v1/applications/${id}/step/rep2/none`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SubmitApplicationRep2Reg
     * @summary Submit loan application - step REP2
     * @request POST:/api/v1/applications/{id}/step/rep2reg
     * @secure
     */
    submitApplicationRep2Reg: (
      id: string,
      data: ApplicationSubmitRep2Dto,
      params: RequestParams = {}
    ) =>
      this.request<Application, any>({
        path: `/api/v1/applications/${id}/step/rep2reg`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SubmitApplicationRevenues
     * @summary Submit loan application - step Revenues
     * @request POST:/api/v1/applications/{id}/step/revenues
     * @secure
     */
    submitApplicationRevenues: (
      id: string,
      data: ApplicationSubmitRevenuesDto,
      params: RequestParams = {}
    ) =>
      this.request<Application, any>({
        path: `/api/v1/applications/${id}/step/revenues`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SubmitApplicationUboFinished
     * @summary Submit loan application - finish step UBO
     * @request POST:/api/v1/applications/{id}/step/ubo/finished
     * @secure
     */
    submitApplicationUboFinished: (id: string, params: RequestParams = {}) =>
      this.request<Application, any>({
        path: `/api/v1/applications/${id}/step/ubo/finished`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SubmitApplicationSetPersonAsUbo
     * @summary Add UBO person
     * @request POST:/api/v1/applications/{id}/step/ubo/person
     * @secure
     */
    submitApplicationSetPersonAsUbo: (
      id: string,
      data: ApplicationSubmitUboDto,
      params: RequestParams = {}
    ) =>
      this.request<Application, any>({
        path: `/api/v1/applications/${id}/step/ubo/person`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Applications
     * @name SubmitApplicationSetPartyAsUbo
     * @summary Add the current user as UBO
     * @request POST:/api/v1/applications/{id}/step/ubo/self
     * @secure
     */
    submitApplicationSetPartyAsUbo: (id: string, params: RequestParams = {}) =>
      this.request<Application, any>({
        path: `/api/v1/applications/${id}/step/ubo/self`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Common
     * @name GetBanks
     * @summary Get list of supported banks
     * @request GET:/api/v1/banks/{country}
     * @secure
     */
    getBanks: (country: string, params: RequestParams = {}) =>
      this.request<BankDto[], any>({
        path: `/api/v1/banks/${country}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Commissions
     * @name GetCommissionStatement
     * @summary Get file for a given commission statement in Base64 format
     * @request GET:/api/v1/commissions/statements/{id}
     * @secure
     */
    getCommissionStatement: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/v1/commissions/statements/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreateCustomer
     * @summary Create customer for the current user
     * @request POST:/api/v1/customers
     * @secure
     */
    createCustomer: (data: CreateCustomerDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/v1/customers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name SubmitContactForm
     * @summary Customer submits a contact form
     * @request POST:/api/v1/customers/{customerId}/contact-form
     * @secure
     */
    submitContactForm: (
      customerId: string,
      data: ContactFormDto,
      params: RequestParams = {}
    ) =>
      this.request<void, void>({
        path: `/api/v1/customers/${customerId}/contact-form`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCustomerRepresentatives
     * @summary Get active PSD connections for a given customer
     * @request GET:/api/v1/customers/{customerId}/psd-connections
     * @secure
     */
    getCustomerRepresentatives: (
      customerId: string,
      params: RequestParams = {}
    ) =>
      this.request<PsdConnection[], any>({
        path: `/api/v1/customers/${customerId}/psd-connections`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreatePsdConnectionStart
     * @summary Create PSD connection - start authorization process
     * @request POST:/api/v1/customers/{customerId}/psd-connections
     * @secure
     */
    createPsdConnectionStart: (
      customerId: string,
      data: {
        iban?: string;
        /** @format uuid */
        bankId?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PsdConnectionDto, any>({
        path: `/api/v1/customers/${customerId}/psd-connections`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreatePsdConnectionFinish
     * @summary Create PSD connection - finish authorization process
     * @request POST:/api/v1/customers/{customerId}/psd-connections/{connectionId}/complete
     * @secure
     */
    createPsdConnectionFinish: (
      customerId: string,
      connectionId: string,
      params: RequestParams = {}
    ) =>
      this.request<PsdConnection, any>({
        path: `/api/v1/customers/${customerId}/psd-connections/${connectionId}/complete`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCustomerRepresentative
     * @summary Get personal data of a customer representative
     * @request GET:/api/v1/customers/{customerId}/representatives/{repId}
     * @secure
     */
    getCustomerRepresentative: (
      customerId: string,
      repId: string,
      params: RequestParams = {}
    ) =>
      this.request<RepresentativeDto, any>({
        path: `/api/v1/customers/${customerId}/representatives/${repId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name Get
     * @summary Get customer
     * @request GET:/api/v1/customers/{id}
     * @secure
     */
    get: (id: string, params: RequestParams = {}) =>
      this.request<CustomerDto, any>({
        path: `/api/v1/customers/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name LinkRepresentativeOnSignup
     * @summary Link user with representative
     * @request POST:/api/v1/customers/{id}/link-user-representative/{repId}
     * @secure
     */
    linkRepresentativeOnSignup: (
      id: string,
      repId: string,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/customers/${id}/link-user-representative/${repId}`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCustomerRepresentatives1
     * @summary Get representatives of a customer
     * @request GET:/api/v1/customers/{id}/representatives
     * @secure
     */
    getCustomerRepresentatives1: (id: string, params: RequestParams = {}) =>
      this.request<RepresentativeDto[], any>({
        path: `/api/v1/customers/${id}/representatives`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Outputs
     * @name GetDocument
     * @summary Get document
     * @request GET:/api/v1/documents/{id}
     * @secure
     */
    getDocument: (id: string, params: RequestParams = {}) =>
      this.request<DocumentDto, any>({
        path: `/api/v1/documents/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetRevenues
     * @summary Get previous month's and predicted next month's revenues for a given customer
     * @request GET:/api/v1/insights/partners/{partnerCode}/merchants/{merchantId}/revenues
     * @secure
     */
    getRevenues: (
      partnerCode: string,
      merchantId: string,
      params: RequestParams = {}
    ) =>
      this.request<RevenueDto, any>({
        path: `/api/v1/insights/partners/${partnerCode}/merchants/${merchantId}/revenues`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetActiveLeadForCustomer
     * @summary Get an active lead for the given customer
     * @request GET:/api/v1/leads/active/customer/{id}
     * @secure
     */
    getActiveLeadForCustomer: (id: string, params: RequestParams = {}) =>
      this.request<LeadDto, any>({
        path: `/api/v1/leads/active/customer/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name LeadContact
     * @summary Customer submits a contact form
     * @request POST:/api/v1/leads/contact
     * @secure
     */
    leadContact: (data: CreateLeadContactDto, params: RequestParams = {}) =>
      this.request<LeadDto, any>({
        path: `/api/v1/leads/contact`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name LeadIndicativeInterest
     * @summary Customer express interest in an offer
     * @request POST:/api/v1/leads/indicative-interest
     * @secure
     */
    leadIndicativeInterest: (
      data: CreateIndicativeInterestDto,
      params: RequestParams = {}
    ) =>
      this.request<LeadDto, any>({
        path: `/api/v1/leads/indicative-interest`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name LeadInterest
     * @summary Customer express interest in an offer
     * @request POST:/api/v1/leads/interest
     * @secure
     */
    leadInterest: (data: CreateInterestDto, params: RequestParams = {}) =>
      this.request<LeadDto, any>({
        path: `/api/v1/leads/interest`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name LeadNoOffer
     * @summary Customer has no active offer
     * @request POST:/api/v1/leads/no-offer
     * @secure
     */
    leadNoOffer: (data: CreateLeadNoOfferDto, params: RequestParams = {}) =>
      this.request<LeadDto, any>({
        path: `/api/v1/leads/no-offer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name LeadOpportunity
     * @summary Customer opens an offer
     * @request POST:/api/v1/leads/opportunity
     * @secure
     */
    leadOpportunity: (
      data: CreateLeadOpportunityDto,
      params: RequestParams = {}
    ) =>
      this.request<LeadDto, any>({
        path: `/api/v1/leads/opportunity`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name LeadPartner
     * @summary Process a lead from partner's platform
     * @request POST:/api/v1/leads/partner
     * @secure
     */
    leadPartner: (data: PartnerLeadRequest, params: RequestParams = {}) =>
      this.request<PartnerLeadDto, any>({
        path: `/api/v1/leads/partner`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Common
     * @name LeadSignup
     * @summary User is signed up
     * @request POST:/api/v1/leads/signup
     * @secure
     */
    leadSignup: (data: CreateLeadSignupDto, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/v1/leads/signup`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Provides basic information about an active loan and the overview of previous loans for a given customer.
     *
     * @tags Loans
     * @name GetLoansOverviewByCustomer
     * @summary Get loans overview for a given customer
     * @request GET:/api/v1/loans/customers/{id}
     * @secure
     */
    getLoansOverviewByCustomer: (id: string, params: RequestParams = {}) =>
      this.request<LoansOverviewDto, any>({
        path: `/api/v1/loans/customers/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetCommissionsByPartner
     * @summary Get a list of commissions for a given partner
     * @request GET:/api/v1/loans/partners/{id}/commissions
     * @secure
     */
    getCommissionsByPartner: (id: string, params: RequestParams = {}) =>
      this.request<CommissionReportEntry[], any>({
        path: `/api/v1/loans/partners/${id}/commissions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetCommissionsByPartner1
     * @summary Get a list of commissions for a given partner
     * @request GET:/api/v1/loans/partners/{id}/commissions/date/{from}
     * @secure
     */
    getCommissionsByPartner1: (
      id: string,
      from: string,
      params: RequestParams = {}
    ) =>
      this.request<CommissionReportEntry[], any>({
        path: `/api/v1/loans/partners/${id}/commissions/date/${from}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetCommissionsByPartner2
     * @summary Get a list of commissions for a given partner
     * @request GET:/api/v1/loans/partners/{id}/commissions/filter/{filter}
     * @secure
     */
    getCommissionsByPartner2: (
      id: string,
      filter: string,
      params: RequestParams = {}
    ) =>
      this.request<CommissionReportEntry[], any>({
        path: `/api/v1/loans/partners/${id}/commissions/filter/${filter}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetCommissionsByPartner3
     * @summary Get a list of commissions for a given partner
     * @request GET:/api/v1/loans/partners/{id}/commissions/filter/{filter}/date/{from}
     * @secure
     */
    getCommissionsByPartner3: (
      id: string,
      filter: string,
      from: string,
      params: RequestParams = {}
    ) =>
      this.request<CommissionReportEntry[], any>({
        path: `/api/v1/loans/partners/${id}/commissions/filter/${filter}/date/${from}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetCommissionStatementsByPartner
     * @summary Get a list of commission statements for a given partner
     * @request GET:/api/v1/loans/partners/{id}/commissions/statements
     * @secure
     */
    getCommissionStatementsByPartner: (
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<CommissionStatement[], any>({
        path: `/api/v1/loans/partners/${id}/commissions/statements`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Loans
     * @name GetLoansOverviewByPartner
     * @summary Get loans overview for a given partner
     * @request GET:/api/v1/loans/partners/{id}/overview
     * @secure
     */
    getLoansOverviewByPartner: (id: string, params: RequestParams = {}) =>
      this.request<PartnerLoansOverviewDto, any>({
        path: `/api/v1/loans/partners/${id}/overview`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Agreements
     * @name ConfirmScheduleAgreement
     * @summary Confirm scheduled agreement
     * @request GET:/api/v1/loans/schedule-agreements/{id}/confirm
     * @secure
     */
    confirmScheduleAgreement: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/loans/schedule-agreements/${id}/confirm`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Agreements
     * @name RejectScheduleAgreement
     * @summary Reject scheduled agreement
     * @request GET:/api/v1/loans/schedule-agreements/{id}/reject
     * @secure
     */
    rejectScheduleAgreement: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/v1/loans/schedule-agreements/${id}/reject`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Provides detailed information about a given loan.
     *
     * @tags Loans
     * @name GetLoan
     * @summary Get loan details
     * @request GET:/api/v1/loans/{id}
     * @secure
     */
    getLoan: (id: string, params: RequestParams = {}) =>
      this.request<LoanDto, any>({
        path: `/api/v1/loans/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Loans, Prolongations
     * @name CreateLoanProlongation
     * @summary Create loan prolongation
     * @request POST:/api/v1/loans/{id}/prolongations
     * @secure
     */
    createLoanProlongation: (
      id: string,
      data: CreateLoanProlongationDto,
      params: RequestParams = {}
    ) =>
      this.request<string, any>({
        path: `/api/v1/loans/${id}/prolongations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Loans, Prolongations
     * @name GetInstallmentsPreviewAfterProlongation
     * @summary Get installments preview after prolongation
     * @request GET:/api/v1/loans/{id}/prolongations/preview
     * @secure
     */
    getInstallmentsPreviewAfterProlongation: (
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<ProlongationPreviewDto, any>({
        path: `/api/v1/loans/${id}/prolongations/preview`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetActiveOfferForCustomer
     * @summary Get an active offer for the given customer
     * @request GET:/api/v1/offers/active/customer/{id}
     * @secure
     */
    getActiveOfferForCustomer: (id: string, params: RequestParams = {}) =>
      this.request<OfferDto, any>({
        path: `/api/v1/offers/active/customer/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetOfferOverviewForPartner
     * @summary Get overview of offers for a given partner
     * @request GET:/api/v1/offers/partners/{id}/overview
     * @secure
     */
    getOfferOverviewForPartner: (id: string, params: RequestParams = {}) =>
      this.request<PartnerOffersOverviewDto, any>({
        path: `/api/v1/offers/partners/${id}/overview`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name StartAddressVerification
     * @summary Start address verification
     * @request POST:/api/v1/parties/address-verifications
     * @secure
     */
    startAddressVerification: (
      data: AddressVerificationChallengeRequest,
      params: RequestParams = {}
    ) =>
      this.request<AddressVerificationChallengeResponse, any>({
        path: `/api/v1/parties/address-verifications`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CompleteAddressVerification
     * @summary Complete address verification
     * @request POST:/api/v1/parties/address-verifications/{id}/response
     * @secure
     */
    completeAddressVerification: (
      id: string,
      data: {
        otp?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/api/v1/parties/address-verifications/${id}/response`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCountries
     * @summary Get countries
     * @request GET:/api/v1/parties/countries
     * @secure
     */
    getCountries: (params: RequestParams = {}) =>
      this.request<CountryDto[], any>({
        path: `/api/v1/parties/countries`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetCompanyRegisterInfoByRegNum
     * @summary Validate a country and business id (regnum) combination and retrieve basic company info
     * @request POST:/api/v1/parties/validate-regnum
     * @secure
     */
    getCompanyRegisterInfoByRegNum: (
      data: {
        /** ISO 3166 alpha-2 country code enum, including metadata. */
        country?: IsoCountryCode;
        regNum?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<RegisterInfo, any>({
        path: `/api/v1/parties/validate-regnum`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetPersonalProfile
     * @summary Get personal profile
     * @request GET:/api/v1/parties/{id}
     * @secure
     */
    getPersonalProfile: (id: string, params: RequestParams = {}) =>
      this.request<PersonalProfileDto, any>({
        path: `/api/v1/parties/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GiveMarketingConsent
     * @summary Give marketing consent
     * @request POST:/api/v1/parties/{id}/consent
     * @secure
     */
    giveMarketingConsent: (id: string, params: RequestParams = {}) =>
      this.request<ConsentDto, any>({
        path: `/api/v1/parties/${id}/consent`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name WithdrawMarketingConsent
     * @summary Withdraw marketing consent
     * @request DELETE:/api/v1/parties/{id}/consent
     * @secure
     */
    withdrawMarketingConsent: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/parties/${id}/consent`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name UpdatePersonalProfile
     * @summary Update personal profile
     * @request PATCH:/api/v1/parties/{id}/personal-profile
     * @secure
     */
    updatePersonalProfile: (
      id: string,
      data: UpdatePersonalProfileDto,
      params: RequestParams = {}
    ) =>
      this.request<PersonalProfileDto, any>({
        path: `/api/v1/parties/${id}/personal-profile`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetList
     * @summary Get the list of partner connections for a given customer
     * @request GET:/api/v1/partner-connections/customers/{id}
     * @secure
     */
    getList: (id: string, params: RequestParams = {}) =>
      this.request<PartnerConnectionDto[], any>({
        path: `/api/v1/partner-connections/customers/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreateNewConnectionFromlead
     * @summary Creates a new partner connection based on the provided Lead
     * @request POST:/api/v1/partner-connections/customers/{id}
     * @secure
     */
    createNewConnectionFromlead: (
      id: string,
      data: CreateConnectionFromLeadDto,
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: `/api/v1/partner-connections/customers/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name HotglueConnectionCreated
     * @summary Creates a new partner connection based on the provided Lead
     * @request POST:/api/v1/partner-connections/customers/{id}/hotglue-connection
     * @secure
     */
    hotglueConnectionCreated: (
      id: string,
      data: HotglueConnectionCreatedDto,
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: `/api/v1/partner-connections/customers/${id}/hotglue-connection`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreateNewPartnerRequest
     * @summary Creates a request to integrate new partner's platform
     * @request POST:/api/v1/partner-connections/customers/{id}/new-partner-requests
     * @secure
     */
    createNewPartnerRequest: (
      id: string,
      data: NewPartnerRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: `/api/v1/partner-connections/customers/${id}/new-partner-requests`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreatePartnerRequest
     * @summary Creates a request to integrate new partner's platform
     * @request POST:/api/v1/partner-connections/customers/{id}/partner-requests
     * @secure
     */
    createPartnerRequest: (
      id: string,
      data: PartnerRequestDto,
      params: RequestParams = {}
    ) =>
      this.request<object, any>({
        path: `/api/v1/partner-connections/customers/${id}/partner-requests`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CreateConnectionShopify
     * @summary Creates a new partner connection for Shopify
     * @request POST:/api/v1/partner-connections/shopify
     * @secure
     */
    createConnectionShopify: (
      data: CreateConnectionShopifyDto,
      params: RequestParams = {}
    ) =>
      this.request<string, any>({
        path: `/api/v1/partner-connections/shopify`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name GetList1
     * @summary Get the list of partners
     * @request GET:/api/v1/partners
     * @secure
     */
    getList1: (params: RequestParams = {}) =>
      this.request<PartnerDto[], any>({
        path: `/api/v1/partners`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Outputs
     * @name GetCurrentTerms
     * @summary Get the current terms & condition in Base64 format
     * @request GET:/api/v1/terms/{country}/current
     * @secure
     */
    getCurrentTerms: (country: FlowpayCountry, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/v1/terms/${country}/current`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Outputs
     * @name GetCurrentTermsRaw
     * @summary Get the current terms & condition in binary format
     * @request GET:/api/v1/terms/{country}/current/raw
     * @secure
     */
    getCurrentTermsRaw: (country: FlowpayCountry, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/api/v1/terms/${country}/current/raw`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Outputs
     * @name GetFileByRevision
     * @summary Get file for a given revision of terms & condition in Base64 format
     * @request GET:/api/v1/terms/{country}/revision/{revision}
     * @secure
     */
    getFileByRevision: (
      country: FlowpayCountry,
      revision: string,
      params: RequestParams = {}
    ) =>
      this.request<string, any>({
        path: `/api/v1/terms/${country}/revision/${revision}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Outputs
     * @name GetFileRawByRevision
     * @summary Get file for a given revision of terms & condition in raw format
     * @request GET:/api/v1/terms/{country}/revision/{revision}/raw
     * @secure
     */
    getFileRawByRevision: (
      country: FlowpayCountry,
      revision: string,
      params: RequestParams = {}
    ) =>
      this.request<File, any>({
        path: `/api/v1/terms/${country}/revision/${revision}/raw`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Outputs
     * @name GetFile
     * @summary Get file for a given terms & condition in Base64 format
     * @request GET:/api/v1/terms/{id}
     * @secure
     */
    getFile: (id: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/v1/terms/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Outputs
     * @name GetFileRaw
     * @summary Get file for a given terms & condition in raw format
     * @request GET:/api/v1/terms/{id}/raw
     * @secure
     */
    getFileRaw: (id: string, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/api/v1/terms/${id}/raw`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Common
     * @name GetExternalUserProfile
     * @summary Get current user's profile.
     * @request GET:/api/v1/users/current
     * @secure
     */
    getExternalUserProfile: (params: RequestParams = {}) =>
      this.request<ExternalUserDto, any>({
        path: `/api/v1/users/current`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
