import { TCustomError } from '../context/error-handler-context';
import i18n from '../i18n/config';
import {
  Address,
  IsoCurrencyCode,
  LoanDto,
  LoanDtoInstallmentDto,
} from '../types/api';

export const parseErrorMessage = (error: TCustomError): string | null => {
  return typeof error === 'string' || error == null ? error : error.error;
};

const accumulateTotalForDay = (
  targetInstallments: Array<LoanDtoInstallmentDto>,
  referenceInstallment: LoanDtoInstallmentDto
): number => {
  const todayInstallments = targetInstallments.filter(
    (instalment) =>
      new Date(instalment.dueDate as string).toDateString() ===
      new Date(referenceInstallment.dueDate as string).toDateString()
  );

  const totalAmount = todayInstallments.reduce(
    (total, current) => total + (current.total ? Number(current.total) : 0),
    0
  );

  const paidAmount = todayInstallments.reduce(
    (total, current) =>
      total + (current.paidTotal ? Number(current.paidTotal) : 0),
    0
  );

  return totalAmount - paidAmount;
};

/**
 * This method returns the closest installment to display, with OVERDUE or PLANNED state
 */

interface IClosestInstallment {
  dueToDate: string;
  accumulatedTotal: number;
  currency: IsoCurrencyCode;
}

export const calculateAmountToPayForInstallments = (
  activeLoans: Array<LoanDto>
): IClosestInstallment | null => {
  if (
    !activeLoans ||
    (Array.isArray(activeLoans) && activeLoans.length === 0)
  ) {
    return null;
  }

  const currentDate = new Date();
  const plannedInstallments: Array<LoanDtoInstallmentDto> = [];
  const overdueInstallments: Array<LoanDtoInstallmentDto> = [];

  activeLoans.forEach((loan) => {
    loan.instalments?.forEach((instalment) => {
      if (instalment.state === 'PLANNED') {
        plannedInstallments.push(instalment);
      } else if (instalment.state === 'OVERDUE') {
        overdueInstallments.push(instalment);
      }
    });
  });

  let accumulatedTotal = 0;
  let installmentToDisplay: LoanDtoInstallmentDto | null = null;

  if (overdueInstallments.length > 0) {
    const oldestOverdueInstalment = overdueInstallments.reduce(
      (oldest, current) => {
        return new Date(current.dueDate as string) <
          new Date(oldest?.dueDate as string) &&
          new Date(current.dueDate as string) >= currentDate
          ? current
          : oldest;
      }
    );

    if (oldestOverdueInstalment) {
      installmentToDisplay = oldestOverdueInstalment;
      accumulatedTotal = accumulateTotalForDay(
        overdueInstallments,
        oldestOverdueInstalment
      );
    }
  } else {
    const earliestPlannedInstallment =
      plannedInstallments.length > 0
        ? plannedInstallments.reduce((earliest, current) =>
            new Date(current.dueDate as string) <
            new Date(earliest?.dueDate as string)
              ? current
              : earliest
          )
        : null;

    if (earliestPlannedInstallment) {
      installmentToDisplay = earliestPlannedInstallment;
      accumulatedTotal = accumulateTotalForDay(
        plannedInstallments,
        earliestPlannedInstallment
      );
    }
  }

  return {
    dueToDate: installmentToDisplay?.dueDate ?? '',
    accumulatedTotal,
    currency: (installmentToDisplay?.currency ?? 'CZK') as IsoCurrencyCode,
  };
};

export const generateRandomNonce = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};

export const safeParseJSON = (data: string) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.error('Error parsing JSON', error);
    return null;
  }
};

export const getPolicyDocumentUrl = (): string => {
  const languageCode = i18n.language;
  const { protocol, hostname, port } = window.location;

  const documentUrl = `${protocol}//${hostname}${
    port ? `:${port}` : ''
  }/policies/privacypolicy_${languageCode}.pdf`;

  return documentUrl;
};

export function parseApiError(err: any): { message: string; logref?: string } {
  let message = 'Something went wrong';
  let logref;

  if (err.response && [400, 422].includes(err.response.status)) {
    if (err.response.data) {
      if (err.response.data.logref) {
        logref = err.response.data.logref;
      }

      if (
        err.response.data['_embedded'] &&
        err.response.data['_embedded'].errors
      ) {
        const errors = err.response.data['_embedded'].errors.map(
          (it: any) => it.message
        );
        message = errors.join(', ');
      }
    }
  }

  return { message, logref };
}

export function formatAddress(value?: Address) {
  if (value === null || value === undefined) return '';

  const items = [];

  if (value.line1) items.push(value.line1);
  if (value.line2) items.push(value.line2);

  if (value.zip || value.city) {
    items.push(
      (value.zip ? value.zip : '') +
        (value.zip && value.city ? ' ' : '') +
        (value.city ? value.city : '')
    );
  }

  if (value.country) items.push(value.country);

  return items.join(', ');
}
