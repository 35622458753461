import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { getInitialData, TUserRegisterData } from './registrationSchema';
import { Business } from './steps/Business';
import { Websites } from './steps/Websites';
import { ICO } from './steps/ICO';
import { ICOData } from './steps/ICOData';
import { NewExecutive } from './steps/NewExecutive';
import { Phone } from './steps/Phone';
import { Pin } from './steps/Pin';
import { FInalStep } from './steps/FInalStep';
import {
  registrationSteps,
  RegistrationSteps,
} from '../../../constants/registrationSteps';
import { useScreenSize } from '../../../hooks';
import { BackButton } from './registration_components/BackButton';
import { FirstStepGreeting } from './registration_components/FirstStepGreeting';
import { RobotImage } from '../../UI/RobotImage';
import { RegistrationProgressBar } from './registration_components/RegistrationProgressBar';
import { RegistrationProgressBarMobile } from './registration_components/RegistrationProgressBarMobile';
import Header from '../../Layout/Header';
import { useAppSelector } from '../../../selectors/selectors';
import Footer from '../../Layout/Footer';
import { RegisterInfo } from '../../../types/api';

declare const window: Window & { dataLayer: Record<string, unknown>[] };

const Registration = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [registrationData, setRegistrationData] = useState(getInitialData());
  const { t } = useTranslation();
  const { currentStep, stepHistory } = registrationData;
  const { isMediumScreen } = useScreenSize();
  const { currentUser } = useAppSelector((state) => state.currentUser);
  const currentCustomer = currentUser?.customers?.[0];
  const infoOrganizationRef = useRef<RegisterInfo | null>(null);

  useEffect(() => {
    // GTM event
    if (currentStep === RegistrationSteps.FinalStep) {
      window.dataLayer?.push({
        event: 'registration_completed',
      });
    }
  }, [currentStep]);

  const customerId = currentCustomer?.id ?? '';

  const progressStep = Math.ceil(
    ((currentStep + 1) / registrationSteps.length) * 100
  );

  const isFirstStep = currentStep === RegistrationSteps.Business;
  const isLastStep = currentStep === RegistrationSteps.FinalStep;

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({ screen_hint: 'signup' });
    }

    if (currentUser?.invited) {
      setRegistrationData((prevState) => {
        return {
          ...prevState,
          currentStep: RegistrationSteps.Phone,
          stepHistory: [0, 1, 2, 3],
        };
      });
    } else if (
      currentUser?.customers?.length &&
      currentUser.customers.length > 0
    ) {
      const isRepLinked = currentCustomer?.linkedRegRepId;
      setRegistrationData((prevState) => {
        return {
          ...prevState,
          ico: currentCustomer?.regNum ?? '',
          currentStep: isRepLinked
            ? RegistrationSteps.Phone
            : RegistrationSteps.ICOData,
          stepHistory: isRepLinked ? [0, 1, 2, 3] : [0, 1, 2], //history to navigate back and forward
        };
      });
    }
  }, [isAuthenticated, currentUser]);

  const updateRegistrationData = (fields: Partial<TUserRegisterData>) => {
    setRegistrationData((prev) => {
      return { ...prev, ...fields };
    });
  };

  const handleMoveToStep = (step: RegistrationSteps, saveHistory = true) => {
    const updatedStepHistory = saveHistory
      ? [...registrationData.stepHistory, currentStep]
      : registrationData.stepHistory;

    const newData = {
      ...registrationData,
      currentStep: step,
      stepHistory: updatedStepHistory,
    };
    setRegistrationData(newData);
  };

  const back = () => {
    const lastStep = stepHistory.at(-1);
    const updatedHistory = stepHistory.slice(0, -1);
    const newData = {
      ...registrationData,
      currentStep: lastStep ?? RegistrationSteps.Business,
      stepHistory: updatedHistory,
    };
    setRegistrationData(newData);
  };

  const steps = {
    [RegistrationSteps.Business]: (
      <Business
        updateRegistrationData={updateRegistrationData}
        title={t('registration.registration-business-header')}
        moveToStep={handleMoveToStep}
        registrationData={registrationData}
        // footer={
        //   <Typography sx={{ marginTop: '1rem' }}>
        //     {t('registration.registration-terms')}{' '}
        //     <a href={getPolicyDocumentUrl()} target="_blank" rel="noreferrer">
        //       {t('common-here')}
        //     </a>
        //     .
        //   </Typography>
        // }
      />
    ),
    [RegistrationSteps.Websites]: (
      <Websites
        registrationData={registrationData}
        moveToStep={handleMoveToStep}
        updateRegistrationData={updateRegistrationData}
      />
    ),
    [RegistrationSteps.ICO]: (
      <ICO
        registrationData={registrationData}
        updateRegistrationData={updateRegistrationData}
        title={t('registration.registration-ico-header')}
        moveToStep={handleMoveToStep}
        infoOrganizationRef={infoOrganizationRef}
      />
    ),
    [RegistrationSteps.ICOData]: (
      <ICOData
        title={t('registration.registration-ico-check-header')}
        moveToStep={handleMoveToStep}
        footer={
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={{ marginTop: '1rem' }}
          >
            <InfoOutlinedIcon color="primary" />
            <Typography variant="body1">
              {t('registration.registration-ico-check-footer')}
            </Typography>
          </Stack>
        }
      />
    ),
    [RegistrationSteps.NewExecutive]: (
      <NewExecutive
        title={t('registration.registration-add-new-executive-header')}
        moveToStep={handleMoveToStep}
        registrationData={registrationData}
        updateRegistrationData={updateRegistrationData}
      />
    ),
    [RegistrationSteps.Phone]: (
      <Phone
        phone={registrationData.phone}
        updateRegistrationData={updateRegistrationData}
        title={t('registration.registration-phone-number')}
        buttonText={t('registration.registration-phone-number-button-text')}
        moveToStep={handleMoveToStep}
      />
    ),
    [RegistrationSteps.Pin]: (
      <Pin
        title={t('registration.registration-pin-confirm-header')}
        moveToStep={handleMoveToStep}
        updateRegistrationData={updateRegistrationData}
        registrationData={registrationData}
      />
    ),
    [RegistrationSteps.FinalStep]: (
      <FInalStep
        title={t('registration.registration-final-step-header')}
        buttonText={t('registration.continue-to-application')}
      />
    ),
  };

  if (!isAuthenticated) {
    return null;
  }

  let displayBackButton = !(isFirstStep || isLastStep);
  if (currentStep === RegistrationSteps.Phone) {
    displayBackButton = false;
  }

  return (
    <>
      <Box sx={{ minHeight: '94vh', backgroundColor: '#F9F9F9' }}>
        <Header
          currentUser={currentUser!}
          customerId={customerId}
          isRegistration
        />

        {isMediumScreen ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                backgroundColor: 'white',
                borderRadius: '20px',
                p: '2rem',
                m: '2rem',
                width: '72.5rem',
              }}
            >
              <Grid md={3}></Grid>
              <Grid md={9} pl={0}>
                {isFirstStep && <FirstStepGreeting />}
              </Grid>
              <Grid
                md={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <RobotImage style={{ height: '250px', width: '200px' }} />
              </Grid>
              <Grid
                md={9}
                xs={12}
                sx={{
                  borderRadius: '.5rem',
                  border: '1px solid #DEDEDE',
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                {steps[currentStep]}
              </Grid>
              <Grid sm={3} alignSelf="end" textAlign="right">
                {displayBackButton ? <BackButton onClick={back} /> : null}
              </Grid>
              <Grid sm={!isFirstStep ? 9 : 12} md={9} pr={0} pl={0}>
                <RegistrationProgressBar
                  isLastStep={isLastStep}
                  progressStep={progressStep}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: 2,
              flexBasis: '100%',
              maxWidth: '100%',
              position: 'relative',
              backgroundColor: 'white',
              borderRadius: '20px',
              margin: '16px 1rem 1rem 1rem',
            }}
          >
            {isFirstStep && <FirstStepGreeting />}
            <Box
              sx={{
                borderRadius: '.5rem',
                border: '1px solid #DEDEDE',
                padding: { xs: '0 1rem', sm: '0 2rem' },
              }}
            >
              {steps[currentStep]}
            </Box>
            <RegistrationProgressBarMobile
              progressStep={progressStep}
              isFirstStep={isFirstStep}
              isLastStep={isLastStep}
              onClick={back}
              displayBackButton={displayBackButton}
            />
          </Box>
        )}
      </Box>
      <Footer transparent />
    </>
  );
};

export default Registration;
