import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useLoading } from '../../../context/loading-context';
import axios from '../../../utils/axios';
import { parseApiError } from '../../../utils/utils';
import Card from '../../UI/Card';
import Loading from '../../UI/Loading';
import { RobotImage } from '../../UI/RobotImage';
import styles from './ApplicationTerms.module.scss';

interface IScheduledAgreementProps {
  userApproval: 'confirm' | 'reject';
}

const ScheduledAgreement: FC<IScheduledAgreementProps> = ({ userApproval }) => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  // const navigate = useNavigate();
  // const { setError } = useErrorHandler();
  const { loading, setLoading } = useLoading();
  const [success, setSuccess] = useState(false);
  const [actualState, setActualState] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);

    let url;
    if (userApproval === 'confirm') {
      url = `loans/schedule-agreements/${id}/confirm`;
    } else {
      url = `loans/schedule-agreements/${id}/reject`;
    }

    //@ts-ignore to ignore type issue
    axios.defaults.skipAuthToken = true;
    axios
      .post(url)
      .then((_res) => {
        setLoading(false);
        setSuccess(true);
      })
      .catch((_err) => {
        setLoading(false);
        setSuccess(false);
        const { message } = parseApiError(_err);
        setActualState(message.toUpperCase().split('ACTUAL STATE: ')[1]);
        // setError(err);
      });
  }, [id, userApproval, setLoading]);

  return (
    <div className={styles['page-wrapper']}>
      {loading && <Loading isLoading={loading} />}
      {!loading && (
        <>
          <RobotImage />
          {success ? (
            <>
              <div className={styles['card-wrapper']}>
                {userApproval === 'confirm' && (
                  <Card>
                    <h1>{t('scheduled-agreement-confirm-title')}</h1>
                    <p>{t('scheduled-agreement-confirm-txt')}</p>
                  </Card>
                )}
                {userApproval === 'reject' && (
                  <Card>
                    <h1>{t('scheduled-agreement-reject-title')}</h1>
                    <p>{t('scheduled-agreement-reject-txt')}</p>
                  </Card>
                )}
              </div>
            </>
          ) : (
            <div className={styles['card-wrapper']}>
              <Card>
                <h1>{t('scheduled-agreement-error-title')}</h1>
                {actualState === 'CANCELED' && (
                  <p>{t('scheduled-agreement-error-rejected-txt')}</p>
                )}
                {actualState === 'CONFIRMED' && (
                  <p>{t('scheduled-agreement-error-confirmed-txt')}</p>
                )}
                {actualState !== 'CANCELED' && (
                  <p>{t('scheduled-agreement-error-txt')}</p>
                )}
                <a href='mailto:support@flowpay.io'>support@flowpay.io</a>
              </Card>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ScheduledAgreement;
