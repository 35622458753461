import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

import { useLoading } from '../../../context/loading-context';
import { useErrorHandler } from '../../../context/error-handler-context';
import axios from '../../../utils/axios';
import ApplicationProgress from './ApplicationProgress';
import ApplicationRep1 from './ApplicationRep1';
import ApplicationRep2 from './ApplicationRep2';
import ApplicationPep from './ApplicationPep';
import ApplicationRevenue from './ApplicationRevenue';
import ApplicationBank from './ApplicationBank';
import ApplicationPsd from './ApplicationPsd';
import ApplicationKyc from './ApplicationKyc';
import ApplicationDone from './ApplicationDone';
import styles from './Application.module.scss';
import {
  ExternalUserDto,
  ApplicationSubmitRep2Dto,
  ApplicationRevenues,
  ActiveApplicationDto,
  PartnerConnectionDto,
  ApplicationSubmitRep2DtoCustom,
  ApplicationInputState,
  ApplicationSubmitUboDto,
} from '../../../types/api';
import { RobotImage } from '../../UI/RobotImage';
import ApplicationSummary from './ApplicationSummary';
import ApplicationUBO from './ApplicationUBO';

interface IRep1SubmitData {
  customerId: string;
  rep1Id?: string;
  offerId: string;
  leadId: string;
}

interface IApplicationProps {
  customerId: string;
  applicationData: ActiveApplicationDto;
  currentUser: ExternalUserDto;
  partner: PartnerConnectionDto | null;
  updateApplicationData: (data: ActiveApplicationDto['application']) => void;
}

const Application: FC<IApplicationProps> = ({
  applicationData,
  currentUser,
  customerId,
  updateApplicationData,
}) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();

  if (!applicationData) {
    return null;
  }

  const { application, product } = applicationData;

  const isRep2 =
    applicationData &&
    application.rep2Id &&
    application.rep2Id === currentUser.userId;

  const isRep2ActionRequired =
    isRep2 &&
    ['NEW', 'SUBMITTED', 'CHECKING', 'SENT_FOR_APPROVING'].includes(
      application.state
    ) &&
    !applicationData.rep2KycValid;

  const handleRep1Submit = (data: IRep1SubmitData) => {
    setLoading(true);
    axios
      .post(`applications`, data)
      .then((res) => {
        updateApplicationData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const handleUboSubmit = (
    type: 'self' | 'with' | 'else',
    data?: ApplicationSubmitUboDto
  ) => {
    setLoading(true);
    let endpoint;
    let payload = data; // Default payload is `data`

    switch (type) {
      case 'self':
        endpoint = `applications/${application.id}/step/ubo/self`;
        payload = undefined; // No payload for `self` case
        break;
      case 'with':
      case 'else':
        endpoint = `applications/${application.id}/step/ubo/person`;
        break;
      default:
        setLoading(false);
        return;
    }

    axios
      .post(endpoint, payload)
      .then(() =>
        axios.post(`applications/${application.id}/step/ubo/finished`, data)
      )
      .then((res) => {
        updateApplicationData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const handleRep2Submit = (
    data: ApplicationSubmitRep2Dto | ApplicationSubmitRep2DtoCustom,
    representativeId: string
  ) => {
    setLoading(true);

    const endpoint =
      representativeId === 'other'
        ? `applications/${application.id}/step/rep2`
        : `applications/${application.id}/step/rep2reg`;

    axios
      .post(endpoint, data)
      .then((res) => {
        updateApplicationData(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRep2NoSubmit = () => {
    setLoading(true);
    axios
      .post(`applications/${application.id}/step/rep2/none`, {})
      .then((res) => {
        updateApplicationData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const handlePepSubmit = (data: boolean) => {
    setLoading(true);
    axios
      .post(`applications/${application.id}/step/pep`, { pep: data })
      .then((res) => {
        updateApplicationData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const handleRevenueSubmit = (data: ApplicationRevenues) => {
    setLoading(true);
    axios
      .post(`applications/${application.id}/step/revenues`, { revenues: data })
      .then((res) => {
        updateApplicationData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const handleBankSubmit = (data: { iban: string }) => {
    setLoading(true);
    axios
      .post(`applications/${application.id}/step/bank`, data)
      .then((res) => {
        updateApplicationData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const handleKycFinish = (repId: string) => {
    setLoading(true);
    axios
      .post(
        `applications/${application.id}/step/kyc/finish/person/${repId}`,
        {}
      )
      .then((res) => {
        updateApplicationData(isRep2 ? null : res.data);
        setLoading(false);

        if (isRep2) navigate(`/${customerId}/overview`);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const handlePsd = (data: {
    application: ActiveApplicationDto['application'];
  }) => {
    updateApplicationData(data.application);
    navigate(`/${customerId}/application`);
  };

  let applicationComponent = null;
  // KYC REP2
  if (isRep2ActionRequired) {
    applicationComponent = (
      <ApplicationKyc
        isRep2={true}
        applicationId={application.id ?? ''}
        repId={application.rep2Id ?? ''}
        handleKycFinish={handleKycFinish}
      />
    );
  } else {
    switch (application.inputState) {
      case ApplicationInputState.REP2:
        applicationComponent = (
          <ApplicationRep2
            applicationId={application.id ?? ''}
            customerId={customerId}
            rep1Id={application.rep1Id}
            currentUser={currentUser}
            handleRep2Submit={handleRep2Submit}
            handleRep2NoSubmit={handleRep2NoSubmit}
          />
        );
        break;

      case ApplicationInputState.PEP:
        applicationComponent = (
          <ApplicationPep handlePepSubmit={handlePepSubmit} />
        );
        break;

      case ApplicationInputState.UBO: {
        applicationComponent = (
          <ApplicationUBO
            updateApplicationData={updateApplicationData}
            applicationId={application.id!}
          />
        );
        break;
      }

      case ApplicationInputState.REVENUE:
        applicationComponent = (
          <ApplicationRevenue
            revenues={application.revenues ?? {}}
            handleRevenueSubmit={handleRevenueSubmit}
            applicationData={applicationData}
          />
        );
        break;

      case ApplicationInputState.BANK:
        applicationComponent = (
          <ApplicationBank
            handleBankSubmit={handleBankSubmit}
            applicationData={applicationData}
          />
        );
        break;

      case ApplicationInputState.PSD:
        applicationComponent = (
          <ApplicationPsd
            applicationId={application.id ?? ''}
            handlePsd={handlePsd}
          />
        );
        break;

      case ApplicationInputState.KYC:
        applicationComponent = (
          <ApplicationKyc
            isRep2={false}
            applicationId={application.id ?? ''}
            repId={application.rep1Id}
            handleKycFinish={handleKycFinish}
          />
        );
        break;

      case ApplicationInputState.DONE:
        applicationComponent = <ApplicationDone customerId={customerId} />;
        break;

      default:
        applicationComponent = (
          <ApplicationRep1
            customerId={customerId}
            leadId={application.leadId ?? ''}
            offerId={application.offerId}
            product={product}
            currentUser={currentUser}
            handleRep1Submit={handleRep1Submit}
          />
        );
        break;
    }
  }

  return (
    <div className={styles['application-wrapper']}>
      <div className={styles['application-body-wrapper']}>
        <RobotImage />
        <div className={styles['body-wrapper']}>
          {applicationComponent}

          {!isRep2 && (
            <div className={styles['progress-wrapper']}>
              <ApplicationProgress
                application={application}
                customerId={customerId}
              />
            </div>
          )}
        </div>
      </div>
      <ApplicationSummary product={product} />
    </div>
  );
};

export default Application;
